<br><br>
<div class="container ">
        <p class="text-center"><span  class="h4 mb-4" style="color:#ffd500"><b>Loan Summary Form</b></span> </p>
        <br>
        
</div>
    
     
<div class="container" style="margin-top: 0px; margin-bottom: 120px;">
    <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">
        
        <div style="display: block;" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
            <p><b>Name</b> : {{data3.fullname}}</p>
            <p><b>CNIC</b> : {{data3.cnic}}</p>
            <p><b>Residential Address</b> : {{data1.currentaddress}}</p>
            <p><b>Loan Amount</b> : {{data1.desiredLoanAmount}}</p>
        </div>

        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
            Loan Account Number :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/loan-account-number.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="loanAccountNumber" [(ngModel)]="data.loanAccountNumber" type="text" value="" placeholder="Loan Account Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
            Mobile Account Number :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/phone.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="mobileAccountNumber" [(ngModel)]="data.mobileAccountNumber" type="text" value="" placeholder=" Mobile Account Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
    
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
            Secured Packet Number 1 :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/1.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" name="securedPacket1Number" [(ngModel)]="data.securedPacket1Number" type="text" value="" placeholder="Secured Packet Number 1" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
                Secured Packet Number 2 :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/2.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" name="securedPacket2Number" [(ngModel)]="data.securedPacket2Number" type="text" value="" placeholder="Secured Packet Number 2" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
                Loan Amount :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/loan-amount.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="loanAmount" [(ngModel)]="data.loanAmount" #box2  (keyup.enter)="onKeycal3(box2.value)"(blur)="onKeycal3(box2.value)" type="text" value="" placeholder="Loan Amount" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
                Mark Up Rate (%):
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/markup-rate.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="markupRate" [(ngModel)]="data.markupRate" #box1  (keyup.enter)="onKeycal2(box1.value)"(blur)="onKeycal2(box1.value)" type="number" placeholder="Mark Up Rate" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Total Mark Up :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/total-amount.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="totalMarkup" [(ngModel)]="data.totalMarkup" type="text" value="" placeholder="Total Mark Up" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Processing Fee :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/fees.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" name="processingFee" [(ngModel)]="data.processingFee" type="text" value="" placeholder=" Processing Fee" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
    
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Period :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/period.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" name="period" [(ngModel)]="data.period" type="text" value="" placeholder=" Period" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Loan Disbursment Date :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/date.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="loanDisbursementDate" [(ngModel)]="data.loanDisbursementDate" type="text" value="" placeholder="mm/dd/yyyy" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
    
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Gold Rate (per Gram) :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/gold-rate.png" style="width: 34px;">
                </div>
                <input class="form-control" name="goldRate" [(ngModel)]="data.c" type="text" value="" placeholder=" Gold Rate" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
    
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Collateral :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/collectral.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="collateralValue" [(ngModel)]="data.collateralValue" type="text" value="" placeholder="collateral" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Current Coverage :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/current-coveragesvg.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="currentCoverage" [(ngModel)]="data.currentCoverage" type="text" value="" placeholder="Current Coverage" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Loan Maturity Date :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/date.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="loanMaturityDate" [(ngModel)]="data.loanMaturityDate" type="text" value="" placeholder="yyyy-mm-dd" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
    
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               Shroff Name :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/shroff-name.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" name="shroffName" [(ngModel)]="data.shroffName" type="text" value="" placeholder="Shroff Name" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
             CMO Name :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/nam.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" name="name" [(ngModel)]="data.name" type="text" value="" placeholder="Name" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
        
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
             CMO Code :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/user-code.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" name="userCode" [(ngModel)]="data.userCode" type="text" value="" placeholder= "User Code" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
            Processing Date :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/date.svg" style="width: 34px;">
                </div>
                <input class="form-control" name="dueDate" [(ngModel)]="data.dueDate" type="date" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
            <p style="color:tomato;float: left;" class="h5">{{error}}</p>
        </div>
        <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
            <p style="color:green;float: left;" class="h5">{{sucess}}</p>
        </div>
        
        <br>
        
    
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;">
             <button class="btn btn-lg" (click)="submit()" type="submit" style="border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">Submit</button>
        </div>
    
    
    </div>
</div>



<!-- <div class="container">
        <br><br>
       <form class="text-center border border-light p-5" action="#!">
            <p class="text-center"><span  class="h4 mb-4" style="color:#ffd500"><b>Loan Summary Form</b></span> </p>
           <br>
            <div style="border-radius: 5px; background: #ffd500;" class="input-container">
                <div style="padding-top: 10px; width: 4%;">
                    <img style="width: 25px;" src="assets/images/icon/shname.svg" />
                </div>
                <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="loanAccountNumber" class="form-control mb-4" [(ngModel)]="data.loanAccountNumber" placeholder="Loan Account Number">
            </div>

            <div style="border-radius: 5px; background: #ffd500;" class="input-container">
                    <div style="padding-top: 10px; width: 4%;">
                        <img style="width: 25px;" src="assets/images/icon/shname.svg" />
                    </div>
                    
                    <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="mobileAccountNumber" class="form-control mb-4" [(ngModel)]="data.mobileAccountNumber" placeholder="Mobile Account Number">
            </div>
           

           <input type="text" style="border: 2px solid #ffd500;" name="securedPacket1Number" class="form-control mb-4" [(ngModel)]="data.securedPacket1Number" placeholder="Secured Packet 1 Number">
           <input type="text" style="border: 2px solid #ffd500;" name="securedPacket2Number" class="form-control mb-4" [(ngModel)]="data.securedPacket2Number" placeholder="Secured Packet 2 Number">
           <input type="text" style="border: 2px solid #ffd500;" name="loanAmount" class="form-control mb-4" [(ngModel)]="data.loanAmount" placeholder="Loan Amount">
           <input type="text" style="border: 2px solid #ffd500;" name="markupRate" class="form-control mb-4" [(ngModel)]="data.markupRate" placeholder="Mark Up Rate">
           <input type="text" style="border: 2px solid #ffd500;" name="totalMarkup" class="form-control mb-4" [(ngModel)]="data.totalMarkup" placeholder="Total Markup">
           <input type="text" style="border: 2px solid #ffd500;" name="processingFee" class="form-control mb-4" [(ngModel)]="data.processingFee" placeholder="Processing Fee">
           <input type="text" style="border: 2px solid #ffd500;" name="period" class="form-control mb-4" [(ngModel)]="data.period" placeholder="Period">
           <input type="text" style="border: 2px solid #ffd500;" name="loanDisbursementDate" class="form-control mb-4" [(ngModel)]="data.loanDisbursementDate" placeholder="Loan Disbursement Date">
           <input type="text" style="border: 2px solid #ffd500;" name="goldRate" [(ngModel)]="data.goldRate" placeholder="Gold Rate" class="form-control mb-4" >
           <input type="text" style="border: 2px solid #ffd500;" name="collateralValue" [(ngModel)]="data.collateralValue" class="form-control mb-4" placeholder="Collateral Value">
           <input type="text" style="border: 2px solid #ffd500;" name="currentCoverage" [(ngModel)]="data.currentCoverage" class="form-control mb-4" placeholder="Current Coverage">
           <input type="text" style="border: 2px solid #ffd500;" name="loanMaturityDate" [(ngModel)]="data.loanMaturityDate" class="form-control mb-4" placeholder="Loan Maturity Date">
           <input type="text" style="border: 2px solid #ffd500;" name="shroffName" [(ngModel)]="data.shroffName" class="form-control mb-4" placeholder="Shroff Name">
           <input type="text" style="border: 2px solid #ffd500;" name="name" [(ngModel)]="data.name" class="form-control mb-4" placeholder="Name">
           <input type="text" style="border: 2px solid #ffd500;" name="userCode" [(ngModel)]="data.userCode" class="form-control mb-4" placeholder="User Code">
           <input type="date" style="border: 2px solid #ffd500;" name="date" [(ngModel)]="data.date" class="form-control mb-4" placeholder="Date">
          
          
           <br><br>
           <a class="btn btn-default" (click)="submit()">Submit</a>
           <br><br>
    
       </form>
       <br><br> 
</div> -->