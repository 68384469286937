<div class="container">
        <div class="col-md-6 col-lg-6 col-xl-6 col-sm-10 col-md-8 col-12 offset-xl-3 bg-white offset-md-2 offset-lg-3 offset-sm-1 pb-5" style="padding: 23px;margin-top: 120px;  background: #FFFFDD!important;">
            <h2 style="padding: 20px 0px;">Verification Code</h2>
            <form action="">
                <div style="display: block;" class="col-lg-12 col-xl-12 pb-4 input-container">
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="assets/images/icon/verify-code.svg" style="width: 30px;">
                        </div>
                        <input class="form-control" type="number" [(ngModel)]="data.verifyCode" name="verifyCode" placeholder="Verification Code" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                
                
                <div class="sign-button text-center">
                    <button class="btn btn mb-3" (click)="submit()" style="background:#F3CB00; padding: 10px 51px;">Verify Code</button>
                    
                </div>
                <div class="row">
                        <div class="col-lg-12 col-xl-12 pb-4 input-container">
                            <p style="color:green">{{sucess}}</p>
                        </div>
                    
                </div>
                <div class="row">
                        
                        <div class="col-lg-12 col-xl-12 pb-4 input-container">
                                <p style="color:red">{{error}}</p>
                        </div>
                       
                </div>
                
            </form>
        </div>
    </div>

    <br><br>
<!-- 
<br><br>
<div class="container">
    <div class="row">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
                <p>Enter the code send on your phone number {{link}}</p>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
            <input type="number" [(ngModel)]="data.verifyCode" name="verifyCode" placeholder="Verification Code" />
        </div>
        <div class="col-lg-2">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-2">
                <button (click)="submit()" class="btn btn-default" type="submit">Verify Code</button>
        </div>
        <div class="col-lg-2">
                <button class="btn btn-default" type="submit" disabled>Resend Verification Code</button>
        </div>
        <div class="col-lg-6">
        </div>
    </div>
    <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-4">
                <p style="color:green">{{sucess}}</p>
            </div>
            <div class="col-lg-4">
            </div>
            <div class="col-lg-2">
            </div>
    </div>
    <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-4">
                    <p style="color:red">{{error}}</p>
            </div>
            <div class="col-lg-4">
            </div>
            <div class="col-lg-2">
            </div>
    </div>
</div>
<br><br> -->