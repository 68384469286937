<h1  mat-dialog-title>Reasons for Deprecation</h1>
    <mat-dialog-content>
        <mat-form-field style="color:#000000;" >
            <textarea matInput rows="6"  type="text" name="data" [(ngModel)]="data.reason" placeholder="Comments"></textarea>
            <mat-label style="color:rgb(54, 54, 54);font-size: 16px;" class="placeholder">Comments* </mat-label>
            <span class="focus-input100"></span>
            <span class="symbol-input100">                    
            </span>                
        </mat-form-field>
    </mat-dialog-content>
<mat-dialog-actions>
    <button  mat-raised-button (click)="closeMe('Cancel')">Cancel</button>
    <button  mat-raised-button (click)="closeMe('Submit')">Submit</button>
</mat-dialog-actions>