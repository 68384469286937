  
  
  
  <!-- <div style="border-radius: 0;width: 55px; -webkit-appearance: none; -moz-appearance: none; appearance: none; background-image: url(https://rupeek.com/images/dropdown.svg); background-size: 9px; background-repeat: no-repeat; background-position: 12% 15px;">
    <select name="language" style="border-width: 0 0 1px 0; font-size: 1rem;"  (change)="modo($event.target.value)">
      <option value="en" selected>English</option>
      <option value="ur">Urdu</option>
    </select>
  </div> -->
<router-outlet></router-outlet>
<div class="footer section-space100" id="searchbar3">
  <!-- footer -->
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="footer-logo">
          <!-- Footer Logo -->
          <img style="width: 120px;height: 80px;" src="assets/images/logo2.png" alt="Borrow - Loan Company Website Templates"> </div>
        <!-- /.Footer Logo -->
      </div>
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
<!--        <div class="row">-->
<!--          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">-->
<!--            <h3 class="newsletter-title">Signup Our Newsletter</h3>-->
<!--          </div>-->
<!--          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">-->
<!--            <div class="newsletter-form">-->
<!--              &lt;!&ndash; Newsletter Form &ndash;&gt;-->
<!--              <form action="https://jituchauhan.com/borrow/bootstrap-4/newsletter.php" method="post">-->
<!--                <div class="input-group">-->
<!--                  <input type="email" class="form-control" id="newsletter" name="newsletter" placeholder="Write E-Mail Address" required>-->
<!--                  <span class="input-group-btn">-->
<!--                <button class="btn btn-default" type="submit">Go!</button>-->
<!--                </span> </div>-->
<!--                &lt;!&ndash; /input-group &ndash;&gt;-->
<!--              </form>-->
<!--            </div>-->
<!--            &lt;!&ndash; /.Newsletter Form &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
        <!-- /.col-lg-6 -->
      </div>
    </div>
    <hr class="dark-line">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="widget-text mt40">
          <!-- widget text -->
          <p translate style="text-align: justify;">UN169</p>
          <p translate style="text-align: justify;">UN176</p>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <p style="cursor: pointer;" class="address-text" translate data-toggle="modal" data-target="#LahoreOffice"><span><i class="icon-placeholder-3 icon-1x"></i> </span>UN170</p>
              <!-- Modal -->
              <div id="LahoreOffice" class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">

                  <!-- Modal content Lahore Head Office-->
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title" translate>UN170</h4>
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.43497320807!2d74.3344594!3d31.45721869999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391906bb41d47ef9%3A0x5bf209d2d2a045c9!2sGoldFin%20Limited!5e0!3m2!1sen!2s!4v1705575620347!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <p style="cursor: pointer;" class="address-text" translate data-toggle="modal" data-target="#ChahmiranOffice"><span><i class="icon-placeholder-3 icon-1x"></i> </span>UN175</p>
              <p style="cursor: pointer;" class="address-text" translate data-toggle="modal" data-target="#GujranwalaOffice"><span><i class="icon-placeholder-3 icon-1x"></i> </span>UN1067</p>
              <!-- Modal -->
              <div id="ChahmiranOffice" class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                  <!-- Modal content Chahmiran Branch Office-->
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title" translate>UN175</h4>
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3398.739374129399!2d74.34095927561414!3d31.58619417418383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDM1JzEwLjMiTiA3NMKwMjAnMzYuNyJF!5e0!3m2!1sen!2s!4v1705040482722!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Modal -->
              <div id="GujranwalaOffice" class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                  <!-- Modal content Gujranwala Branch Office-->
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title" translate>UN1067</h4>
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3376.400973155598!2d74.17602557563875!3d32.19342447391304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzLCsDExJzM2LjMiTiA3NMKwMTAnNDMuMCJF!5e0!3m2!1sen!2s!4v1705040439722!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.widget text -->
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
            <div class="widget-footer mt40">
              <!-- widget footer -->
              <ul class="listnone">
                <li><a href="/" translate>UN163</a></li>
                <li><a href="aboutus" translate>UN164</a></li>
                <li><a href="emergencyloan" translate>UN165</a></li>
                <li><a href="loancalculator" translate>UN166</a></li>
                <li><a href="faq" translate>UN167</a></li>
                <li><a href="contactus" translate>UN168</a></li>
              </ul>
            </div>
            <!-- /.widget footer -->
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
            <div class="widget-footer mt40">
              <!-- widget footer -->
              <ul class="listnone">
                <li><a href="emergencyloan" translate>UN23</a></li>
    <!--            <li><a href="salaryloan">Salary Loan</a></li>-->
                <li><a href="businessloan" translate>UN26</a></li>
              </ul>
            </div>
            <!-- /.widget footer -->
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
            <div class="widget-social mt40">
              <!-- widget footer -->
              <ul class="listnone">
                <li><a href="https://www.facebook.com/GOLDFINPVTLTD" translate><i class="fa fa-facebook"></i>UN160</a></li>
                <li><a href="https://www.twitter.com/GOLDFINPVTLTD" translate><i class="fa fa-twitter"></i>UN161</a></li>
                <li><a href="https://www.linkedin.com/in/GOLDFINPVTLTD" translate><i class="fa fa-linkedin"></i>UN162</a></li>
              </ul>
            </div>
            <!-- /.widget footer -->
    
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-8 col-8" align="right" style="padding-bottom: 13px;">
            <a href="https://jamapunji.pk">
              <img src="../assets/media/icons/png/jamapunji.jpg" id="jamapunji" alt="" style="width:100%;">
            </a>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-4" style="padding-bottom: 13px;">
            <a href="https://www.secp.gov.pk">
              <img src="../assets/media/icons/png/secp.png" alt="" id="secp" style="width: 100%;">
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <a href="https://sdms.secp.gov.pk/~sdmsadmn/">
              <img src="../assets/media/icons/png/SDMS_logo-768x122.jpg" id="secplong" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /.footer -->
<div class="tiny-footer">
  <!-- tiny footer -->
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" id="searchbar4234">

      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-right">
        <a href="terms"><p style="color: #FFFFFF" translate>UN159</p></a>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-right">
      <a href="terms"><p style="color: #FFFFFF" translate>UN159</p></a>
    </div>
  </div>
</div>
 -->