<div class="page-header1" style="linear-gradient:(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(../images/cover-3.jpg) no-repeat center">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h2 class="display-4" style="color: aliceblue;">CEO Message</h2>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="wrapper-content bg-white ">
                <div class="about-section pinside40">
					<div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <!-- <div class="team-block"> -->
                                        <div class="img text-center"> 
                                            <img src="assets/images/tariq-mohar-sb.PNG" alt="Borrow - Loan Company Website Template" class="img-fluid"> </div>
                                            <div class="team-content kg">
                                            <p class="lead text-center" style="margin-top:9px; line-height: 25px;">
                                                <b translate>UN70</b><br>
                                                (<small translate>UN1087</small>) 
                                            </p>
                                        </div>
                                    <!-- </div> -->
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9">
                                    <p translate>UN1075</p>
									<ul>
										<li translate>UN1077</li>
										<li translate>UN1078</li>
										<li translate>UN1080</li>
										<li translate>UN1081</li>
										<li translate>UN1082</li>
										<li translate>UN1083</li>
										<li translate>UN1084</li>
									</ul>
									<p translate>UN1085</p>
									<p translate>UN1086</p>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</div>