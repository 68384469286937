<br>
<div style="padding: 100px;">
    <h3 class=" mb-2" >Terms & Conditions</h3>
    <h3 class=" mb-2" >I hereby confirm that</h3>
</div>
<div style="padding-left: 100px; padding-right: 100px;">
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>1. </b>I authorize Gold fin/Al Falah Bank to debit any of my account
        maintained at Bank Al Falah to deduct any outstanding amount (
        including principal, mark up ,any other charges if any)
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>2. </b>
        Gold fin/Al Falah Bank is authorized to open and inspect my gold
        collateral bag in my absence if I fail to appear despite being
        notified by Gold fin/Bank Al Falah Bank.
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>3. </b>
        The gold collateral may be obtained upon the settlement of
outstanding principal and markup, along with other charges (if
any)
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>4. </b>
        On maturity, a loan can be rolled over by paying accrued mark up
and processing fee within 60 days of due date, otherwise Gold fin/
Al Falah Bank reserves the right to liquidate the collateral
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>5. </b>
        The Gold fin/Al Falah Bank reserves the right to auction my
collateral to recover the loan. The action may or not take place in
my presence if I fail to appear on the designated time despite
being notified
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>6. </b>
        In case of collateral liquidation, the residual amount (if any) after
recovery of loan repayment, will be credited in my account, and
will be handed over to me upon my request.
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>7. </b>
        Mark up will be charged on additional days of loan usage if
payment is not made on time.
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>8. </b>
        If the loan amount is neither paid in due neither time nor the loan
is rolled over, I will be declared as defaulter. During this time , late
payment charges will be applicable as per Gold fin/Bank Al Falah
Bank’s policy
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>9. </b>
        In case of decline in gold prices, Gold fin/ Al Falah Bank reserve
the right to ask for additional gold (margin) or claim for potential
loan payment.
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>10. </b>
        It is my responsibility to collect repayment receipt, in case of
repayment at the branch. Gold fin/Bank Al Falah Bank will not
accept any claim without the receipt
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>11. </b>
        The loan is being given on bullet terms. However, in case of
partial payment, the markup will be paid on the whole amount at
the time of loan settlement.
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>12. </b>
        Gold fin/Al Falah Bank reserves the right to charge the
markup.
    </p>
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>13. </b>
        Upon the maturity, loan may be rolled over as per the
following conditions
</p>
<ul style="padding-left: 50px; padding-right: 50px;">
    <li>
        By paying markup and processing fee within 29 days.
    </li>
    <li>
        After 29 days, by payment of 40% principal, markup and
processing fee, the rest of the 60% amount will be rolled
over
    </li>
</ul>
    
    <p class="mb-2" style="line-height: 1.7 !important;">
        <b>14. </b>
        Gold fin/Al Falah Bank will not rollover for extension in loan
tenure or to improve its classification.
    </p>
</div>
<br>
<br>