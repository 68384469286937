<br>
<br>
<p class="text-center"><span  class="h4 mb-4" style="color:#ffd500"><b>COP Portal</b></span> </p>
<br>
<br>
<table class="table">
    <thead>
        <tr style="text-align: center;">
            <th>Form Number</th>
            <th>Full Name</th>
            <th>CNIC</th>
            <th>View Form</th>
            <th>Reason</th>
            <th>Current Status</th>
            <th>Change Status</th>
        </tr>
    </thead>
    <tbody>
        <tr style="text-align: center;">
            <th scope="row">134</th>
            <td>Malik Amir Hamza</td>
            <td>38302-3261959-5</td>
            <td>
                <a href="cop2" class="btn btn-outline-secondary">View Form</a>
            </td>
            <td>Your Applications meets our criteria</td>
            <td>Approved</td>
            <td>
                <div class="dropdown">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Approved
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="">Active</a>
                        <a class="dropdown-item" href="#">Inactive</a>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<br>