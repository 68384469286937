<div class="page-header2">
</div>
<div class="container">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="wrapper-content bg-white">
        <div class="section-scroll pinside60" id="section-about">
          <h1>About Salary Loan – Rs. 50,000 to 300,000</h1>
          <p class="lead">Salary loan is the obvious choice of all salaried employees aged 20- 64 years, having valid CNIC, who can offer Gold such as bars, jewelry and ornaments, can be pledged as collateral to avail loan to meet their domestic and emergent needs.</p>
          <hr>

        <div class="section-scroll" id="section-feature">
          <div class="pinside60">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="section-title mb60">
                  <h2>Features of Salary Loan</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="feature-icon mb30">
                  <div class="icon mb20"><i class="icon-clock icon-default icon-2x"></i></div>
                  <h3 class="frontoie">Lowest mark up</h3>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="feature-icon mb30">
                  <div class="icon mb20"><i class="icon-light-bulb-1 icon-default icon-2x"></i></div>
                  <h3 class="frontoie">No guarantee required</h3>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="feature-icon mb30">
                  <div class="icon mb20"><i class="icon-money icon-default icon-2x"></i></div>
                  <h3 class="frontoie">No penalty on early repayment</h3>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="feature-icon mb30">
                  <div class="icon mb20"><i class="icon-settings-6 icon-default icon-2x"></i></div>
                  <h3 class="frontoie">Settlement at maturity of the loan period</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-scroll" id="section-eleigiblity">
          <div class=" bg-light pinside60">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="section-title mb60">
                  <h2>Salary Loan - Eligibility</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="feature-blurb mb30">
                  <h3>Age</h3>
                  <p>All salaried employees aged 20-64 years</p>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">

                <div class="feature-blurb mb30">
                  <h3>Requirement</h3>
                  <ul class="listnone bullet bullet-check-circle-default">
                    <li>Valid CNIC</li>
                    <li>Copy of utility bills</li>
                    <li>Visiting card/ letter head, if available</li>
                    <li>Latest Salary slip (within last 3 months)</li>
                    <li>Job certificate/justification</li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">

                <div class="feature-blurb mb30">
                  <h3>Gold</h3>
                  <p>All salaried employees who can offer Gold such as bars, jewelry and ornaments, can be pledged as collateral to avail loan to meet their domestic and emergent needs. </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-3 col-md-6 col-sm-offset-3 col-sm-12 col-12"> </div>
            </div>
          </div>
        </div>

        <div class="section-scroll" id="section-apply">
          <div class="bg-light pinside60">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="section-title mb60 text-center">
                  <h1>Apply for a Loan</h1>
                  <p>Now apply for a Salary Loan online, All you need to do is provide your details below application form.</p>
                  <a class="btn btn-default">Coming Soon</a>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="widget-share">
          <ul class="listnone">
            <li><a href="#" class="btn-share btn-facebook" title="Share on Facebook"><i class="fa fa-facebook"></i></a></li>
            <li><a href="#" class="btn-share btn-twitter" title="Share on Twitter"><i class="fa fa-twitter"></i></a></li>
            <li>
              <a href="#" class="btn-share btn-google" title="Share on Google"> <i class="fa fa-google"></i></a>
            </li>
            <li><a href="#" class="btn-share btn-linkedin" title="Share on Linkedin"><i class="fa fa-linkedin"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
