<div class="page-header1" style="linear-gradient:(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(../images/cover-3.jpg) no-repeat center">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h2 class="display-4" style="color: aliceblue;">Board of Directors</h2>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="wrapper-content bg-white ">
                <div class="about-section pinside40">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <!-- <div class="team-block"> -->
                                    <div class="img"> 
                                        <img src="assets/images/syed-nadeem-hussain.png" alt="Borrow - Loan Company Website Template" class="img-fluid"> </div>
                                        <div class="team-content kg">
                                        <p class="lead text-center" style="line-height: 25px;">
                                            <b translate>UN68</b><br>
                                            (<small translate>UN69</small>) 
                                        </p>
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9">
                                    <p translate>UN1046</p>
                                    <p translate>UN1047</p>
                                    <p translate>UN1048</p>
                                    <p translate>UN1049</p>
                                    <p translate>UN1050</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="wrapper-content bg-white ">
                <div class="about-section pinside40">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <!-- <div class="team-block"> -->
                                        <div class="img"> 
                                            <img src="assets/images/tariq-mohar.png" alt="Borrow - Loan Company Website Template" class="img-fluid"> </div>
                                            <div class="team-content kg">
                                            <p class="lead text-center" style="margin-top:9px; line-height: 25px;">
                                                <b translate>UN70</b><br>
                                                (<small translate>UN71</small>) 
                                            </p>
                                        </div>
                                    <!-- </div> -->
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9">
                                    <p translate>UN1051</p>
                                    <p translate>UN1052</p>
                                    <p translate>UN1053</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="wrapper-content bg-white ">
                <div class="about-section pinside40">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <!-- <div class="team-block mb30"> -->
                                    <div class="img"> 
                                        <img src="assets/images/tariq-anwar.png" alt="Borrow - Loan Company Website Template" class="img-fluid"> </div>
                                        <div class="team-content kg">
                                        <p class="lead text-center" style="margin-top:9px; line-height: 25px;">
                                            <b translate>UN72</b><br>
                                            (<small translate>UN73</small>) 
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9">
                                    <p translate>UN1054</p>
                                    <p translate>UN1055</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="wrapper-content bg-white ">
                <div class="about-section pinside40">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <!-- <div class="team-block mb30"> -->
                                        <div class="img"> 
                                            <img src="assets/images/samar-masood-soofi.png" alt="Borrow - Loan Company Website Template" class="img-fluid"> </div>
                                            <div class="team-content kg">
                                            <p class="lead text-center" style="margin-top:9px; line-height: 25px;">
                                                <b translate>UN1056</b><br>
                                                (<small translate>UN1057</small>) 
                                            </p>
                                        </div>
                                    <!-- </div> -->
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9">
                                    <p translate style="text-align: justify;">UN1058</p>
                                    <p translate style="text-align: justify;">UN1059</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>