import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-governance',
  template: `
    <p>
      governance works!
    </p>
  `,
  styles: []
})
export class GovernanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
