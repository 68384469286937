<div class="container ">
    <h4 class="text-center h4 mb-4" style="margin-top: 67px;"><b style="color:#FFD500;">Verification</b> Form for Verification Officer</h4>
    <h4 class="text-center h4 mb-4" ><b style="color:#FFD500;">Verification Form</b></h4>
 </div>
    




  <div class="container" style="margin-top: 0px; margin-bottom: 120px;">
        <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">

            <div style="display: block;" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                <p><b>Name</b> : {{data3.fullname}}</p>
                <p><b>CNIC</b> : {{data3.cnic}}</p>
                <p><b>Residential Address</b> : {{data1.currentaddress}}</p>
                <p><b>Loan Amount</b> : {{data1.desiredLoanAmount}}</p>
            </div>



    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
        <label class="control-label " for="email">
          Personal Information (Positive/Negative As per LAF):
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="assets/media/icons/persnol-.svg" style="width: 34px;">
            </div>
            <textarea class="form-control" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" rows="3" id="personalInformation" name="personalInformation" [(ngModel)]="data.personalInformation"></textarea>
          
            <!-- <input class="form-control" name="personalInformation" [(ngModel)]="data.personalInformation" type="text" placeholder="Personal Information (Positive/Negative As per LAF)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
         -->
        </div>
    </div>
    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
        <label class="control-label " for="email">
         Professional Information (Positive/Negative As per LAF):
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/professional-inform.png" style="width: 34px;">
            </div>
            <textarea class="form-control" rows="3" id="professionalInformation" name="professionalInformation" [(ngModel)]="data.professionalInformation" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          
            <!-- <input class="form-control" name="professionalInformation" type="text" [(ngModel)]="data.professionalInformation" placeholder=" Professional Information (Positive/Negative As per LAF)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
         -->
        </div>
    </div>
    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
        <label class="control-label " for="email">
          Financial Information(Positive/Negative As per LAF):
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/financial-inform.svg" style="width: 34px;">
            </div>
            <textarea class="form-control" rows="3" id="financialInformation" name="financialInformation" [(ngModel)]="data.financialInformation" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          
            <!-- <input class="form-control" name="financialInformation" type="text" [(ngModel)]="data.financialInformation" placeholder="Financial Information (Positive/Negative As per LAF)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
             -->
        </div>
    </div>


    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
        <label class="control-label " for="email">
          Neighbor Check- Residence(Positive/Negative):
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/neighbar-icon.svg" style="width: 34px;">
            </div>
            <textarea class="form-control" rows="3" id="neighborcheck_residence" name="neighborcheck_residence" [(ngModel)]="data.neighborcheck_residence" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          
            <!-- <input class="form-control" name="neighborcheck_residence" type="text" [(ngModel)]="data.neighborcheck_residence" placeholder="Neighbor Check- Residence(Positive/Negative)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
         -->
        </div>
    </div>
    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
        <label class="control-label " for="email">
            Neighbor Check- Business(Positive/Negative):
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/business-neighbar.svg" style="width: 34px;">
            </div>
            <textarea class="form-control" rows="2" id="neighborcheck_business" name="neighborcheck_business" [(ngModel)]="data.neighborcheck_business" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          
            <!-- <input class="form-control" name="neighborcheck_business" [(ngModel)]="data.neighborcheck_business" type="text" placeholder="Neighbor Check- Business(Positive/Negative):" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        -->
        </div>
    </div>
  
    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
        <label class="control-label " for="email">
          If Negative give reason:
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/negative.png" style="width: 34px;">
            </div>
            <textarea class="form-control" rows="3" id="negative_reason" name="negative_reason" [(ngModel)]="data.negative_reason" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          
            <!-- <input class="form-control" name="negative_reason" type="text" [(ngModel)]="data.negative_reason" placeholder="If Negative give reason" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        -->
        </div>
    </div>
    <div class="col-lg-12 col-xl-12 ">
    <h4 class="mt-2 mb-3">Additional Remarks</h4>
    </div>

    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
         Personal Information:
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/persnol-.svg" style="width: 34px;">
            </div>

            <textarea class="form-control" rows="2" id="remarks_personalInformation" name="remarks_personalInformation" [(ngModel)]="data.remarks_personalInformation" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          
            <!-- <input class="form-control" name="remarks_personalInformation" type="text" [(ngModel)]="data.remarks_personalInformation" placeholder="Personal Information" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        -->
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
         Professional Information:
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/professional-inform.png" style="width: 34px;">
            </div>
            <textarea class="form-control" rows="2" id="remarks_professionalInformation" name="remarks_professionalInformation" [(ngModel)]="data.remarks_professionalInformation" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          
            <!-- <input class="form-control" name="remarks_professionalInformation" type="text" [(ngModel)]="data.remarks_professionalInformation" placeholder="Professional Information" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
         -->
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
         Financial Information:
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/financial-inform.svg" style="width: 34px;">
            </div>
            <textarea class="form-control" rows="2" id="remarks_financialInformation" name="remarks_financialInformation" [(ngModel)]="data.remarks_financialInformation" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          
            <!-- <input class="form-control" name="remarks_financialInformation" type="text" [(ngModel)]="data.remarks_financialInformation" placeholder="Financial Information" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
         -->
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
         Verification Result(Positive/Negative):
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/verification.svg" style="width: 34px;">
            </div>

            <textarea class="form-control" rows="3" id="verificationResults" name="verificationResults" [(ngModel)]="data.verificationResults" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" ></textarea>
          

            <!-- <input class="form-control" name="verificationResults" type="text" [(ngModel)]="data.verificationResults"  placeholder=" Verification Result(Positive/Negative)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
         -->
        </div>
    </div>
   
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               VO Name :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/media/icons/name-icon.svg" style="width: 34px;">
                </div>
                <input class="form-control" type="text" name="name" [(ngModel)]="data.name" placeholder="Name" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
        </div>
        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
               User Code :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/media/icons/user-code.svg" style="width: 34px;">
                </div>
                <input class="form-control" type="text" name="userCode" [(ngModel)]="data.userCode" placeholder="User Code" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
    </div>


    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
        </label>
        <div class="input-group">
            <div class="input-group-addon addons1" >
               <p style="position: relative; top: 8px; color: #000; padding: 0px 36px;">Picture 1 : </p>
            </div>
            <input class="form-control" type="file" name="image11" (change)="uploadImageCNIC2($event)"  style="padding: 15px;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;resize: none;border-left: 1px #000!important;height: calc(2.6em + .75rem + 14px);"/>
        </div>
    </div>

    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
            </label>
            <div class="input-group">
                <div class="input-group-addon addons1" >
                   <p style="position: relative; top: 8px; color: #000; padding: 0px 36px;">Picture 2 : </p>
                </div>
                <input class="form-control" type="file" name="image12" (change)="uploadImageResidenceUtilityBills($event)"  style="padding: 15px;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;resize: none;border-left: 1px #000!important;height: calc(2.6em + .75rem + 14px);"/>
            </div>
    </div>

    <br>
    <p class="col-lg-12" *ngIf="showPic1 == true || showPic2 == true"><b>If Uploaded Pictures</b></p>
    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;" *ngIf="showPic1 == true">
        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Picture 1 : <br><br><a style="color:black" href="{{baseURL}}/{{data.picture1}}">Download and View Picture 1</a></p>
        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.picture1}}" />
    </div>
    <br>
    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;" *ngIf="showPic2 == true">
            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Picture 2 : <br><br><a style="color:black" href="{{baseURL}}/{{data.picture2}}">Download and View Picture 2</a></p>
            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.picture2}}" />
    </div>
    <br>
    <div class="col-lg-12" *ngIf="showPic1 == false && showPic2 == false">
            <p class="col-lg-12" ><b>No Uploaded Pictures</b></p>
    </div>



    <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;">
         <button class="btn btn-lg" (click)="submit()" type="submit" style="border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">Submit</button>
    </div>


      </div>
</div>


<!-- 
<div class="container">
        <br><br> 
       <form class="text-center border border-light p-5" action="#!">
       
           <p class="h4 mb-4">Verification Form for Verification Officer</p>
            <br>
            <p><span  class="h5 mb-4" style="color:#ffd500"><b>Verification Form</b></span> </p>
           <br>
           <input type="text" style="border: 2px solid #ffd500;" name="personalinformation" class="form-control mb-4" [(ngModel)]="data.personalinformation" placeholder="Personal Information (positive/ negative as per LAF)">
           <input type="text" style="border: 2px solid #ffd500;" name="professionalinformation" class="form-control mb-4" [(ngModel)]="data.professionalinformation" placeholder="Professional Information (positive/ negative as per LAF)">
           <input type="text" style="border: 2px solid #ffd500;" name="financialinformation" class="form-control mb-4" [(ngModel)]="data.financialinformation" placeholder="Financial Information (positive/ negative as per LAF)">
           <br>
           <input type="text" style="border: 2px solid #ffd500;" name="neighborresidenceinformation" class="form-control mb-4" [(ngModel)]="data.neighborresidenceinformation" placeholder="Neighbor check - Residence (positive/ negative)">
           <input type="text" style="border: 2px solid #ffd500;" name="neighborbusinessinformation" class="form-control mb-4" [(ngModel)]="data.neighborbusinessinformation" placeholder="Neighbor check - Business (positive/ negative)">
           <input type="text" style="border: 2px solid #ffd500;" name="negativeresason" class="form-control mb-4" [(ngModel)]="data.negativeresason" placeholder="If negative, give reason">
           
           <label style="float: left;">Additional Remarks :</label>
           <input type="text" style="border: 2px solid #ffd500;" name="personalinformationremarks" class="form-control mb-4" [(ngModel)]="data.personalinformationremarks" placeholder="Personal Information">
           <input type="text" style="border: 2px solid #ffd500;" name="professionalinformationremarks" class="form-control mb-4" [(ngModel)]="data.professionalinformationremarks" placeholder="Professional Information">
           <input type="text" style="border: 2px solid #ffd500;" name="financialinformationremarks" class="form-control mb-4" [(ngModel)]="data.financialinformationremarks" placeholder="Financial Information">
           <br>
           <input type="text" style="border: 2px solid #ffd500;" name="verificationresults" class="form-control mb-4" [(ngModel)]="data.verificationresults" placeholder="Verification Results (Positive/Negative)">
           
          
          
        
          
          
           <br><br>
           <a class="btn btn-default" (click)="submit()">Submit</a>
           <br><br>
    
       
       </form>
       <br><br> 
       </div> -->