<div class="container ">
        <h1 class="text-center mb-5" style="margin-top: 67px;"><b style="color:#FFD500;">Loan </b> Application Form (LAF)</h1>
        <div class="col-xl-10 col-12  offset-0 offset-xl-1 mb-5 mt-2 ">
                <div class="row">
                        <div style="display: block;" *ngIf="data1[0].csoId && data1[0].csoName" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                                <p><b>CSO Name </b> : {{data1[0].csoName}}</p>
                                <p><b>CSO ID </b> : {{data1[0].csoId}}</p>
                        </div>
                        <div style="display: block;" *ngIf="data1[0].boId && data1[0].boName" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                                <p><b>BO Name </b> : {{data1[0].boName}}</p>
                                <p><b>BO ID </b> : {{data1[0].boId}}</p>
                        </div>
                        <div style="display: block;" *ngIf="data1[0].bsId && data1[0].bsName" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                                <p><b>BS Name </b> : {{data1[0].bsName}}</p>
                                <p><b>BS ID </b> : {{data1[0].bsId}}</p>
                        </div>
                </div>
                <br><br>
          <div class="row">
              <div class="col-xl-3 col-3">
                  <img src="./assets/media/media/icons/persnol-information.svg" class="progress-icon1"  alt="">
              </div>
              <div class="col-xl-3 col-3">
                  <img src="./assets/media/media/icons/professional-information.svg" class="progress-icon" alt="">
              </div>
              <div class="col-xl-3 col-3">
                  <img src="./assets/media/media/icons/finnincial.svg" class="progress-icon" alt="">
              </div>
              <div class="col-xl-3 col-3">
                  <img src="./assets/media/media/icons/general.svg" class="progress-icon mr-5" alt="">
              </div>
          </div>
        </div>
    
        <div class="col-xl-10 offset-0 offset-xl-1 mb-2 ">
            <ul id="progressbar00">
                <li id="account"><p>Personal Information</p></li>
                <li id="personal"><p>Professional Information</p></li>
                <li  id="payment"><p>Financial Information</p></li>
                <li class="active" id="confirm" ><p> General Information</p></li>
            </ul>
        </div>
     </div>
    
     <div class="container" style="margin-top: 0px; margin-bottom: 120px;">
        <form action="">
            <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">
                <div style="display: block;" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                    <p><b>Customer Name</b> : {{data1[0].fullname}}</p>
                    <p><b>CNIC</b> : {{data1[0].cnic}}</p>
                </div>
                <br><br>
        <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
                <label class="control-label " for="email">
                        Would you like to recommend someone for this loan :
                </label>
                <div class="input-group">
                        <div class="input-group-addon addons" style="padding: 4px 34px;">
                                <p style="color: #212529;position: relative;top: 9px;">Recommend ?</p>
                        </div>
                    <select [(ngModel)]="data.recommend" name="recommend" #mySelect2 (change)='onOptionsSelected2(mySelect2.value)' class="form-control" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;resize: none;border-left: 1px #000!important;height: calc(2.6em + .75rem + 14px);">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                      </select>
                </div>
        </div>
    
    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
    </div>
    
        <div style="display: block;" *ngIf="recommend" class="col-lg-12 col-xl-12">
    
    
            
            <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
                <div class="input-group">
                    <div class="input-group-addon" style="border-right: 1px solid #000 !important" >
                        <input name="refname" [(ngModel)]="data.refname" id="refname" class="mb-4" placeholder="Name 1" style="margin-bottom: 0px !important;border: 2px solid #808080 !important;border-top-left-radius:0px !important;border-bottom-left-radius:0px !important;padding: 19px 32px;resize: none;border-right: 1px solid #000 !important;border-left: 1px solid #000 !important; background: #FFFFDD!important; border: 0px!important;height: calc(2.7em + .75rem + -18px);"/>
                    </div>
                    <input type="text" [(ngModel)]="data.refmob" name="refmob" placeholder="Mobile Number 1 ______________ (Our Representative will call them for Appointment)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 46px;resize: none;border-left: 1px solid #000!important;background: #fff!important;"/>
                </div>
            </div>
        
        
            <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
                <div class="input-group" >
                    <div class="input-group-addon addons1" style="border-right: 1px solid #000 !important">
                        <input name="refname1" [(ngModel)]="data.refname1" id="refname1" class="mb-4" placeholder="Name 2" style="margin-bottom: 0px !important;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important; background: #FFFFDD!important; border: 0px!important;height: calc(2.7em + .75rem + -18px);"/>
                    </div>
                    <input type="text" [(ngModel)]="data.refmob1" name="refmob1" placeholder="Mobile Number 2 ______________ (Our Representative will call them for Appointment)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 46px;resize: none;border-left: 1px solid #000 !important;background: #fff!important;"/>
                </div>
            </div>
        
            <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
                <div class="input-group">
                    <div class="input-group-addon addons1" style="border-right: 1px solid #000 !important">
                        <input name="refname2" [(ngModel)]="data.refname2" id="refname2" class="mb-4" placeholder="Name 3" style=" margin-bottom: 0px !important;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important; background: #FFFFDD!important; border: 0px!important;height: calc(2.7em + .75rem + -18px);"/>
                    </div>
                    <input type="text" [(ngModel)]="data.refmob2" name="refmob2" placeholder="Mobile Number 3 ______________ (Our Representative will call them for Appointment)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 46px;resize: none;border-left: 1px solid #000 !important;background: #fff!important;"/>
                </div>
            </div>
            <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
                <div class="input-group">
                    <div class="input-group-addon addons1" style="border-right: 1px solid #000 !important" >
                        <input name="refname3" [(ngModel)]="data.refname3" id="refname3" class="mb-4" placeholder="Name 4" style="margin-bottom: 0px !important;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important; background: #FFFFDD!important; border: 0px!important;height: calc(2.7em + .75rem + -18px);"/>
                    </div>
                    <input type="text" [(ngModel)]="data.refmob3" name="refmob3" placeholder="Mobile Number 4 ______________ (Our Representative will call them for Appointment)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 46px;resize: none;border-left: 1px solid #000 !important;background: #fff!important;"/>
                </div>
            </div>
            <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
                <div class="input-group">
                    <div class="input-group-addon addons1" style="border-right: 1px solid #000 !important">
                        <input name="refname4" [(ngModel)]="data.refname4" id="refname4" class="mb-4" placeholder="Name 5" style="margin-bottom: 0px !important;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important; background: #FFFFDD!important; border: 0px!important;height: calc(2.7em + .75rem + -18px);"/>
                    </div>
                    <input type="text" [(ngModel)]="data.refmob4" name="refmob4" placeholder="Mobile Number 5 ______________ (Our Representative will call them for Appointment)" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 46px;resize: none;border-left: 1px solid #000 !important;background: #fff!important;"/>
                </div>
            </div>

               <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-12"></div>
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <a class="btn btn-default" href="lafForm2/{{userId}}">Back</a>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <a class="btn btn-default" (click)="submitlaf3()">Submit Changes</a>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <a class="btn btn-default" href="lafForm4/{{userId}}">Next</a>
                    </div>
               </div>

        </div>
        <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
            <a class="btn btn-default" href="lafForm4/{{userId}}">SKIP</a>
    </div>
          </div>
       </form>
    </div>
<!-- 
<div class="container">
        <br><br> 
       <form class="text-center border border-light p-5" action="#!">
       
           <p class="h4 mb-4">Loan Application Form (LAF)</p>
            <br>
            <p><span  class="h6 mb-4" style="color:#bebcbc">Personal Information</span> - <span  class="h6 mb-4" style="color:#bebcbc">Professional Information</span> - <span class="h6 mb-4" style="color:#bebcbc">Financial Information</span>- <span class="h5 mb-4" style="color:#ffd500"><b>General Information</b></span></p>
           <br>           
           <label style="float: left;">Would you like to recommend someone for this loan :</label>
           <div style="border-radius: 0;width: 55px; -webkit-appearance: none; -moz-appearance: none; appearance: none; background-size: 9px; background-repeat: no-repeat; background-position: 12% 15px;">
            <select name="recommend" #mySelect2 (change)='onOptionsSelected2(mySelect2.value)' style="background: transparent ;border-width: 0 0 1px 0; font-size: 1rem;"  [(ngModel)]="data.recommend">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
           </div>
           <br>
           <div *ngIf="recommend">
              <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                  <p style="height: calc(1.5em + .75rem + 8px);background: #FFCA22; padding: 9px; min-width: 20%; color: black;">Name</p>
                  <p style="display: block; width: 100%; height: calc(1.5em + .75rem + 6px); font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; background-color: #fff; background-clip: padding-box; border-radius: .25rem; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;border: 2px solid #FFD500!important; border-top-left-radius: 0px; border-bottom-left-radius: 0px; padding: 7px 30px; resize: none;" >Mobile Number</p>
              </div>

              <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                  <input name="refname" [(ngModel)]="data.refname" id="refname" class="mb-4" placeholder="Name 1" style="border: 2px solid #FFD500!important;height: calc(1.5em + .75rem + 8px); padding: 9px; min-width: 20%; color: black;">
                  <input type="text" [(ngModel)]="data.refmob" name="refmob" placeholder="Mobile Number 1 ______________ (Our Representative will call them for Appointment)" style="display: block; width: 100%; height: calc(1.5em + .75rem + 2px); font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; background-color: #fff; background-clip: padding-box; border-radius: .25rem; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;border: 2px solid #FFD500!important; border-top-left-radius: 0px; border-bottom-left-radius: 0px; padding: 19px 30px; resize: none;" >
              </div>

              <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                  <input name="refname1" [(ngModel)]="data.refname1" id="refname1" class="mb-4" placeholder="Name 2" style="border: 2px solid #FFD500!important;height: calc(1.5em + .75rem + 8px); padding: 9px; min-width: 20%; color: black;">
                  <input type="text" [(ngModel)]="data.refmob1" name="refmob1" placeholder="Mobile Number 2 ______________ (Our Representative will call them for Appointment)" style="display: block; width: 100%; height: calc(1.5em + .75rem + 2px); font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; background-color: #fff; background-clip: padding-box; border-radius: .25rem; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;border: 2px solid #FFD500!important; border-top-left-radius: 0px; border-bottom-left-radius: 0px; padding: 19px 30px; resize: none;" >
              </div>

              <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                  <input name="refname2" [(ngModel)]="data.refname2" id="refname2" class="mb-4" placeholder="Name 3" style="border: 2px solid #FFD500!important;height: calc(1.5em + .75rem + 8px); padding: 9px; min-width: 20%; color: black;">
                  <input type="text" [(ngModel)]="data.refmob2" name="refmob2" placeholder="Mobile Number 3 ______________ (Our Representative will call them for Appointment)" style="display: block; width: 100%; height: calc(1.5em + .75rem + 2px); font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; background-color: #fff; background-clip: padding-box; border-radius: .25rem; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;border: 2px solid #FFD500!important; border-top-left-radius: 0px; border-bottom-left-radius: 0px; padding: 19px 30px; resize: none;" >
              </div>

              <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                  <input name="refname3" [(ngModel)]="data.refname3" id="refname3" class="mb-4" placeholder="Name 4" style="border: 2px solid #FFD500!important;height: calc(1.5em + .75rem + 8px); padding: 9px; min-width: 20%; color: black;">
                  <input type="text" [(ngModel)]="data.refmob3" name="refmob3" placeholder="Mobile Number 4 ______________ (Our Representative will call them for Appointment)" style="display: block; width: 100%; height: calc(1.5em + .75rem + 2px); font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; background-color: #fff; background-clip: padding-box; border-radius: .25rem; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;border: 2px solid #FFD500!important; border-top-left-radius: 0px; border-bottom-left-radius: 0px; padding: 19px 30px; resize: none;" >
              </div>

              <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                  <input name="refname4" [(ngModel)]="data.refname4" id="refname4" class="mb-4" placeholder="Name 5" style="border: 2px solid #FFD500!important;height: calc(1.5em + .75rem + 8px); padding: 9px; min-width: 20%; color: black;">
                  <input type="text" [(ngModel)]="data.refmob4" name="refmob4" placeholder="Mobile Number 5 ______________ (Our Representative will call them for Appointment)" style="display: block; width: 100%; height: calc(1.5em + .75rem + 2px); font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; background-color: #fff; background-clip: padding-box; border-radius: .25rem; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;border: 2px solid #FFD500!important; border-top-left-radius: 0px; border-bottom-left-radius: 0px; padding: 19px 30px; resize: none;" >
              </div>


           
            </div>

           
          
           <br><br>
   
           <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <a class="btn btn-default" href="lafForm2/{{userId}}">Back</a>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <a class="btn btn-default" (click)="submitlaf3()">Submit Changes</a>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <a class="btn btn-default" href="lafForm4/{{userId}}">Next</a>
                </div>
              </div>
    
       
       </form>
       <br><br> 
       </div> -->