import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cop',
  templateUrl: './cop.component.html',
  styleUrls: ['./cop.component.css']
})
export class CopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
