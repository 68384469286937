<div class="container">

    <div class="col-xl-6 offset-xl-3" style="margin-top: 5rem;">
        <p class="text-center"><span  class="h4 mb-4" style="color:#ffd500"><b>BS Portal</b></span> </p>
       
        <div class="input-group">
            <input name="search" [(ngModel)]="searchText" type="text" class="form-control" placeholder="Search by Date,CNIC" style="padding: 23px 10px;">
        </div>
        <div style="padding-top:20px;padding-left: 95px;">
                <a style="height: 40px;" class="btn btn-default btn-sm" href="bo2">BS Portal</a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a style="height: 40px" class="btn btn-default btn-sm" href="due_payments">Due Accounts</a>
        </div>
        
    </div>
</div>
<div class="loader" *ngIf="!data.data"></div>
<div class="limiter">
    <div class="container-fluid mt-5 mb-5"  style="overflow-x:auto; padding: 19px 30px; " >
        <div class="wrap-table100">
            <div class="table100">
                <table>
                    <thead style="position:sticky;">
                        <tr class="table100-head">
                            <th class="column1" style="border-right: 1px solid rgb(255 255 255 / 39%);">Full Name</th>
                            <th class="column2" style="border-right: 1px solid rgb(255 255 255 / 39%); padding: 10px;">CNIC</th>
                            <th class="column3" style="border-right: 1px solid rgb(255 255 255 / 39%); padding: 10px;">Profession</th>
                            
                            <th class="column4" style="border-right: 1px solid rgb(255 255 255 / 39%); padding: 10px;">Date</th>
                            <th class="column12" style="border-right: 1px solid rgb(255 255 255 / 39%); padding: 10px;">Time</th>
                          
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let event of data.data | filter:searchText | orderBy: key : reverse | paginate: { itemsPerPage: 10, currentPage: p };">
                            <td class="column1">{{event.fullname}}</td>
                            <td class="column2">{{event.cnic}}</td>
                            <td class="column3">&nbsp;&nbsp;{{event.profession}}</td>
                       
                            <td class="column4">&nbsp;&nbsp;{{event.date}}</td>
                           <td class="column12">{{event.time}}</td>
                          
                        </tr>

                        
                        

                    </tbody>
                </table>
                <pagination-controls style="background: #f3cb00;" (pageChange)="p = $event"></pagination-controls>
                    
            </div>
        </div>
    </div>
</div>






<br>
<br>
<br>