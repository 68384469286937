<div class="page-header2">

</div>

<div class="container">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="wrapper-content bg-white pinside40">
        <div class="contact-form mb60">
          <div class=" ">
            <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
              <div class="mb60  section-title text-center  ">
                <!-- section title start-->
                <h1 translate>UN81</h1>
                <p translate>UN82</p>
              </div>
            </div>

            <form class="contact-us" method="post" >
                <!-- Text input-->
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="sr-only control-label" for="name">name<span class=" "> </span></label>
                      <input id="name" name="name" type="text" placeholder="Name" class="form-control input-md" required>
                    </div>
                  </div>
                  <!-- Text input-->
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="sr-only control-label" for="email">Email<span class=" "> </span></label>
                      <input id="email" name="email" type="email" placeholder="Email" class="form-control input-md" required>
                    </div>
                  </div>
                  <!-- Text input-->
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="sr-only control-label" for="phone">Phone<span class=" "> </span></label>
                      <input id="phone" name="phone" type="text" placeholder="Phone" class="form-control input-md" required>
                    </div>
                  </div>
                  <!-- Select Basic -->
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="message"> </label>
                      <textarea class="form-control" id="message" rows="7" name="message" placeholder="Message"></textarea>
                    </div>
                  </div>
                  <!-- Button -->
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button type="submit" class="btn btn-default" translate>UN83</button>
                  </div>
                </div>
            </form>
          </div>
        </div>
        <!-- /.section title start-->
      </div>
    </div>
  </div>
</div>
