<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="wrapper-content bg-white ">
                <div class="about-section pinside40">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h2 class="mb30" translate>UN1007</h2>
                            <p style="text-align:justify" translate>UN1008</p>
                            <p style="text-align:justify" translate>UN1009</p>
                        </div>
                    </div><br><br>
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <img src="assets/images/inves-info.png" style="width: 100%; height: 250px;" alt="Insitor’s investment will help GoldFin" class="img-fluid img-thumbnail">
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                            <p style="text-align:justify" translate>UN1010</p>
                            <p style="text-align:justify" translate>UN1011</p>
                        </div>
                    </div><br><br>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>