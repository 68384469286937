<div class="container mt-5">
    <h1 class="text-center mb-2" style="margin-top: 67px;"><b style="color:#FFD500;">Loan </b> Eligibility</h1>
 </div>   

 <div *ngIf="recommend">
    <div class="container" style=" margin-bottom: 120px;">
        <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" margin-top: 70px!important; padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">
          
          <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
              <h4 class="text-center mb-5" ><b style="color:#FFD500;">Salaried Person</b></h4>
          </div>
      
      
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
              <label class="control-label " for="email">
              Total Monthly Income:
              </label>
              <div class="input-group">
                  <div class="input-group-addon">
                  <img src="./assets/loan/total-monthly-income.svg" style="width: 34px;">
                  </div>
                  <input disabled class="form-control" disabled disabled name="totalmonthlyincome" type="text" value="{{data.totalmonthlyincome}}" placeholder="Total Monthly Income" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
              </div>
          </div>
      
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
              <label class="control-label " for="email">
             Total Monthly Expenses:
              </label>
              <div class="input-group">
                  <div class="input-group-addon">
                  <img src="./assets/loan/total-monthly-expenses.svg" style="width: 34px;">
                  </div>
                  <input disabled class="form-control" disabled name="totalexpense" type="text" value="{{data.totalexpense}}" placeholder="Total Monthly Expenses" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
              </div>
          </div>
          
      
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
              <label class="control-label " for="email">
              Desired Loan Ammount:
              </label>
              <div class="input-group">
                  <div class="input-group-addon">
                  <img src="./assets/loan/desired-amount.svg" style="width: 34px;">
                  </div>
                  <input disabled class="form-control" disabled name="desiredLoanAmount" type="text" value="{{data.desiredLoanAmount}}" placeholder="Desired Loan Ammount" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
              </div>
          </div>
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
            Market Value of Net Weight of Gold :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/desired-amount.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" disabled name="finalLoanAmount2" type="text" value="{{finalLoanAmount2}}" placeholder="Desired Loan Ammount" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
            </div>
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
              <label class="control-label " for="email">
              Effective Repayment Capacity:
              </label>
              <div class="input-group">
                  <div class="input-group-addon">
                  <img src="./assets/loan/repayment-capacity.svg" style="width: 34px;">
                  </div>
                  <input disabled class="form-control" disabled name="result" type="text" value="{{data.result3}}" placeholder=" Effective Repayment Capacity" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
              </div>
          </div>
          
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
             Result :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/user-code.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" disabled name="result2" type="text" value="{{data.result2}}" placeholder=" Effective Repayment Capacity" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
            </div>


            </div>
      </div>
 </div>

 <div *ngIf="recommend2">

    <div class="container" style=" margin-bottom: 120px;">
        <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" margin-top: 70px!important; padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">
          
          <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
              <h4 class="text-center mb-5" ><b style="color:#FFD500;">Business Person</b></h4>
          </div>
      
      
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
              <label class="control-label " for="email">
              Total Monthly Income:
              </label>
              <div class="input-group">
                  <div class="input-group-addon">
                  <img src="./assets/loan/total-monthly-income.svg" style="width: 34px;">
                  </div>
                  <input disabled class="form-control" name="monthly" type="text" value="{{data.a}}" placeholder="Total Monthly Income" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
              </div>
          </div>
      
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
              <label class="control-label " for="email">
             Total Monthly Expenses:
              </label>
              <div class="input-group">
                  <div class="input-group-addon">
                  <img src="./assets/loan/total-monthly-expenses.svg" style="width: 34px;">
                  </div>
                  <input disabled class="form-control" name="expenses" type="text" value="{{data.b}}" placeholder="Total Monthly Expenses" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
              </div>
          </div>
          
      
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
              <label class="control-label " for="email">
              Desired Loan Ammount:
              </label>
              <div class="input-group">
                  <div class="input-group-addon">
                  <img src="./assets/loan/desired-amount.svg" style="width: 34px;">
                  </div>
                  <input disabled class="form-control" name="desiredLoanAmount" type="text" value="{{data.desiredLoanAmount}}" placeholder="Desired Loan Ammount" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
              </div>
          </div>
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
            Market Value of Net Weight of Gold :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/desired-amount.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" disabled name="finalLoanAmount2" type="text" value="{{finalLoanAmount2}}" placeholder="Desired Loan Ammount" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
            </div>
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
              <label class="control-label " for="email">
              Effective Repayment Capacity:
              </label>
              <div class="input-group">
                  <div class="input-group-addon">
                  <img src="./assets/loan/repayment-capacity.svg" style="width: 34px;">
                  </div>
                  <input disabled class="form-control" name="result" type="text" value="{{data.result3}}" placeholder=" Effective Repayment Capacity" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
              </div>
          </div>
          
          
          <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
             Result :
            </label>
            <div class="input-group">
                <div class="input-group-addon">
                <img src="./assets/loan/user-code.svg" style="width: 34px;">
                </div>
                <input disabled class="form-control" name="result2" type="text" value="{{data.result2}}" placeholder=" Effective Repayment Capacity" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
            </div>
            </div>

            </div>
      </div>

 </div>


<!-- 


<div class="container" style="margin-top: 80px; margin-bottom: 120px;">
    <h1 class="text-center mb-2" style="margin-top: 67px;"><b style="color:#FFD500;">Loan </b> Eligibility</h1>
    
    <div *ngIf="recommend">
            <p>Salaried Person</p>
            <p>Total monthly Income : {{data.totalmonthlyincome}}</p>
            <p>Total monthly Expense : {{data.totalexpense}}</p>
            <p>Desired Loan Amount : {{data.desiredLoanAmount}}</p>
            <p>Effective Repayment Capacity : {{data.result}}</p>
    </div>

    <div *ngIf="recommend2">
        <p>Business Person</p>
        <p>Total monthly Income : {{data.a}}</p>
        <p>Total monthly Expense : {{data.b}}</p>
        <p>Desired Loan Amount : {{data.desiredLoanAmount}}</p>
        <p>Effective Repayment Capacity : {{data.result}}</p>
    </div>
    <br><br>
    
</div> -->
