import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-director-elections',
  template: `
    <p>
      director-elections works!
    </p>
  `,
  styles: []
})
export class DirectorElectionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
