<div class="page-header3">
</div>
<div class="container">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="wrapper-content bg-white pinside40">
        <div class="">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="section-title mb30">
                <h1 translate>UN136</h1>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 st-accordion">
              <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" translate>UN137</a> </h4>
                  </div>
                  <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div class="panel-body">
                      <p translate>UN158</p>
                      <p translate>UN174</p>
                    </div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingTwo">
                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" translate>UN138</a> </h4>
                  </div>
                  <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div class="panel-body" translate>UN157</div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingFour">
                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="true" aria-controls="collapseFour"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" translate> UN139</a> </h4>
                  </div>
                  <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                    <div class="panel-body">
                      <p translate>UN156</p>
                      <p translate>UN174</p>
                    </div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingFive">
                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="true" aria-controls="collapseFive"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" translate>UN140</a> </h4>
                  </div>
                  <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                    <div class="panel-body" translate>UN155</div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingSix">
                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="true" aria-controls="collapseSix"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix" translate>UN141</a> </h4>
                  </div>
                  <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                    <div class="panel-body" translate>UN154</div>
                  </div>
                </div>
<!--                <div class="panel panel-default">-->
<!--                  <div class="panel-heading" role="tab" id="headingSeven">-->
<!--                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"> What charges are involved in the processing of MSQ?</a> </h4>-->
<!--                  </div>-->
<!--                  <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">-->
<!--                    <div class="panel-body">A Processing Fee of 1% of the loan amount will be charged. The Processing Fee will be deducted from the loan amount after the loan is disbursed in the customer’s bank account. No amount in any form is required to be paid by the customer at stage of the loan processing. Due deductions will be made at the time of disbursal of loan.</div>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingEight">
                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="true" aria-controls="collapseEight"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight" translate>UN142</a> </h4>
                  </div>
                  <div id="collapseEight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
                    <div class="panel-body">
                      <p translate>UN153</p>
                      <p translate>UN174</p>
                    </div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingNine">
                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="true" aria-controls="collapseNine"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine" translate> UN143</a> </h4>
                  </div>
                  <div id="collapseNine" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
                    <div class="panel-body" translate>UN152
                    <ul style="margin-left:20px">
                      <li style="font-weight: bold;list-style: none;" translate>UN23</li>
                      <ul style="margin-left:25px">
                        <li translate>UN122</li>
                        <li translate>UN123</li>
                        <li translate>UN124</li>
                        <li translate>UN127</li>
                      </ul>
<!--                      <b>Salary Loan</b>-->
<!--                      <li>Valid CNIC</li>-->
<!--                      <li>Copy of utility bills</li>-->
<!--                      <li>Visiting card/ letter head, if available</li>-->
<!--                      <li>Latest Salary slip (within last 3 months)</li>-->
<!--                      <li>Job certificate/justification</li>-->
                      <li style="font-weight: bold;list-style: none;"  translate>UN26</li>
                      <ul style="margin-left:25px">
                        <li translate>UN122</li>
                        <li translate>UN123</li>
                        <li translate>UN124</li>
                        <li translate>UN125</li>
                        <li translate>UN126</li>
                        <li translate>UN127</li>
                      </ul>
                    </ul>
                    <p translate>UN174</p>
                    </div>
                  </div>
                </div><div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTen">
                  <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="true" aria-controls="collapseTen"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen" translate> UN144 </a> </h4>
                </div>
                <div id="collapseTen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen">
                  <div class="panel-body" translate>
                    <p translate>UN151</p>
                    <p translate>UN174</p>
                  </div>
                </div>
              </div>
<!--                <div class="panel panel-default">-->
<!--                <div class="panel-heading" role="tab" id="heading11">-->
<!--                  <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse11" aria-expanded="true" aria-controls="collapse11"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse11" aria-expanded="false" aria-controls="collapse11"> How much interest/ markup rate is applicable on MSQ? </a> </h4>-->
<!--                </div>-->
<!--                <div id="collapse11" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading11">-->
<!--                  <div class="panel-body">The mark up is linked with the SBP Kibor rate and will be adjusted as per changes in the Kibor rate. Based on the current Kibor, the annual markup rate is expected to be 19%-20%.</div>-->
<!--                </div>-->
<!--              </div>-->
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="heading12">
                    <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse12" aria-expanded="true" aria-controls="collapse12"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse12" aria-expanded="false" aria-controls="collapse12" translate> UN145</a> </h4>
                  </div>
                  <div id="collapse12" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading12">
                    <div class="panel-body">
                      <p translate>UN150</p>
                      <p translate>UN174</p>
                    </div>
                  </div>
                </div><div class="panel panel-default">
                <div class="panel-heading" role="tab" id="heading13">
                  <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse13" aria-expanded="true" aria-controls="collapse13"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse13" aria-expanded="false" aria-controls="collapse13" translate> UN146</a> </h4>
                </div>
                <div id="collapse13" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading13">
                  <div class="panel-body">
                    <p translate>UN149</p>
                    <p translate>UN174</p>
                  </div>
                </div>
              </div><div class="panel panel-default">
                <div class="panel-heading" role="tab" id="heading14">
                  <h4 class="panel-title"><i style="color: #F3CB00;cursor: pointer;" class="fa fa-plus" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse14" aria-expanded="true" aria-controls="collapse14"></i><a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse14" aria-expanded="false" aria-controls="collapse14" translate>UN147 </a> </h4>
                </div>
                <div id="collapse14" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading14">
                  <div class="panel-body" translate>UN148</div>
                </div>
              </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
