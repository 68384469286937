<br><br>
<div style="margin-left: 25px;height: 40px; border-radius: 20px;border-top : 2px solid #f3cb00;border-right : 2px solid #f3cb00;border-left : 2px solid #f3cb00;width:180px; ">
<p style="padding-left: 28px; padding-top: 8px;">Loan acquisition</p>
</div>
<div style="margin-left: 25px;height: 40px; border-radius: 20px;border-top : 2px solid #f3cb00;border-bottom : 2px solid #f3cb00;border-right : 2px solid #f3cb00;border-left : 2px solid #f3cb00;width:180px; ">
<p style="padding-left: 28px; padding-top: 8px;">Collection</p>
</div>
<br><br>
<div style="margin-right: 80px; margin-left: 270px; margin-top: -120px;">

<table class="table">
        <thead>
            <tr style="text-align: center;">
                <th>CNIC</th>
                <th>View Details</th>
                <th>Loan Account Number</th>
                <th>Mobile Account Number</th>
                <th>Mark up</th>
                <th>Processing Fee</th>
                <th>Due Date</th>
            </tr>
        </thead>
        <tbody>
            <tr style="text-align: center;">
                <td>38302-3261959-5</td>
                <td>
                    <a href="cmo" class="btn btn-outline-secondary">View Details</a>
                </td>
                <td>
                    27890
                </td>
                <td>
                    923000688951
                </td>
                <td>
                    2.6%
                </td>
                <td>
                    Rs. 560
                </td>
                <td>
                    9-12-2020
                </td>
            </tr>
        </tbody>
    </table>
    <br>
</div>