<div *ngIf="!trustvo" class="container">
        <div class="container ">
                <h4 class="text-center h4 mb-5" style="margin-top: 67px;"><b style="color:#FFD500;"> Collateral Assessment Form</b> – Feedback</h4>
             </div>
                
              <div class="container" style="margin-top: 0px; margin-bottom: 120px;">
            
                    <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">
            
                        <div style="display: block;" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                            <p><b>Name</b> : {{data3.fullname}}</p>
                            <p><b>CNIC</b> : {{data3.cnic}}</p>
                            <p><b>Residential Address</b> : {{data3.currentaddress}}</p>
                        </div>
            
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                     Shroff Name :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/shroof.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled name="shroffName" [(ngModel)]="data1[0].shroffName" type="text" value="" placeholder="Shroff Name" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                        Shroff Certificate Number :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/date.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled name="shroffCertificateNumber" type="text" [(ngModel)]="data1[0].shroffCertificateNumber" placeholder="Shroff Certificate Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                
            
                <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
                    <label class="control-label " for="email">
                        Nature Of Gold :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                          <img src="./assets/media/icons/gold-nature.svg" style="width: 34px;">
                        </div>
                        <select name="natureOfGold" disabled [(ngModel)]="data1[0].natureOfGold" class="form-control" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;resize: none;border-left: 1px #000!important;height: calc(2.6em + .75rem + 14px);">
                            <option value="Bars">Bars</option>
                            <option value="Biscuits">Biscuits</option>
                            <option value="Jewelry">Jewelry</option>
                            <option value="Ornaments">Ornaments</option>
                        </select>
                    </div>
                </div>
            
                  
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/1.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled name="details1" type="text" [(ngModel)]="data1[0].detailsGold[0]" placeholder="1." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/2.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled name="details2" type="text" [(ngModel)]="data1[0].detailsGold[1]" placeholder="2." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/3.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled name="details3" type="text" [(ngModel)]="data1[0].detailsGold[2]" placeholder="3." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/4.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled name="details4" type="text" [(ngModel)]="data1[0].detailsGold[3]" placeholder="4." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
                    <label class="control-label " for="email">
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/5.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled name="details5" type="text" [(ngModel)]="data1[0].detailsGold[4]" placeholder="5." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                
                
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                        Gross weight (grams) as per Shroff Certificate :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/groos-weight.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="grossWeight" [(ngModel)]="data1[0].grossWeight" placeholder="Gross weight (grams) as per Shroff Certificate" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>    
            
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                        Net Weight (grams) as per Shroff Certificate :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/gram-weight.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="netWeight" [(ngModel)]="data1[0].netWeight" placeholder="Net Weight (grams) as per Shroff Certificate" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                        Market Value as per Shroff Certificate :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/market-value.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="marketValue" [(ngModel)]="data1[0].marketValue" placeholder="Market Value as per Shroff Certificate" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                       Secured Packet (1) Number :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/secured-packet.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="securedPacket1Number" [(ngModel)]="data1[0].securedPacket1Number" placeholder="Secured Packet (1) Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                      Gross Weight By CMO :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/gram-weight.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="grossWeightCMO" [(ngModel)]="data1[0].grossWeightCMO" placeholder="Gross Weight By CMO" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                       Secured Packet (2) Number :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/secured-packet.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="securedPacket2Number" [(ngModel)]="data1[0].securedPacket2Number" placeholder="Secured Packet (2) Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                       Bar Code Number :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/bar-code.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="barCodeNumber" [(ngModel)]="data1[0].barCodeNumber" placeholder="Secured Packet (2) Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                        CMO Name :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/name-icon.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="name" [(ngModel)]="data1[0].name" placeholder="Name" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                       User Code :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/user-code.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="userCode" [(ngModel)]="data1[0].userCode" placeholder="User Code" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
                <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                    <label class="control-label " for="email">
                       Date :
                    </label>
                    <div class="input-group">
                        <div class="input-group-addon">
                        <img src="./assets/media/icons/date.svg" style="width: 34px;">
                        </div>
                        <input class="form-control" disabled type="text" name="date" [(ngModel)]="data1[0].date" placeholder="Date" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                    </div>
                </div>
            
                <p class="col-lg-12" *ngIf="showPic1 == true || showPic2 == true"><b>If Uploaded Pictures</b></p>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;" *ngIf="showPic1 == true">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Picture 1 : <br><br><a style="color:black" href="{{baseURL}}/{{data1[0].picture1}}">Download and View Picture 1</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data1[0].picture1}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;" *ngIf="showPic2 == true">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Picture 2 : <br><br><a style="color:black" href="{{baseURL}}/{{data1[0].picture2}}">Download and View Picture 2</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data1[0].picture2}}" />
                </div>
                <div class="col-lg-12" *ngIf="showPic1 == false && showPic2 == false">
                        <p class="col-lg-12" ><b>No Uploaded Pictures</b></p>
                </div>
            
            
            
            
            
                  </div>
        </div>
</div>

<div *ngIf="trustvo" class="container">
        <br><br>  
       <form class="text-center border border-light p-5" action="#!">
       
           <p class="h4 mb-4">CMO Officer Feedback</p>
            <br>
           
        </form>
    <div class="border border-light p-5">
        <h3>{{record}}</h3>
    </div>
</div>
      

<!-- 
<div *ngIf="!trustvo" class="container">
        <br><br>
       <form class="text-center border border-light p-5" action="#!">
       
           <p class="h4 mb-4">CMO Feedback</p>
        </form>
    <div class="border border-light p-5">
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Shroff Name : <b>{{this.data1[0].shroffName}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Shroff Certificate Number : <b>{{this.data1[0].shroffCertificateNumber}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Nature Of Gold : <b>{{this.data1[0].natureOfGold}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Gross Weight : <b>{{this.data1[0].grossWeight}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Net Weight : <b>{{this.data1[0].netWeight}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Market Value : <b>{{this.data1[0].marketValue}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Secured Packet Number : <b>{{this.data1[0].securedPacket1Number}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Gross Weight CMO : <b>{{this.data1[0].grossWeightCMO}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Secured Packet Number : <b>{{this.data1[0].securedPacket2Number}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Bar Code Number : <b>{{this.data1[0].barCodeNumber}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Name : <b>{{this.data1[0].name}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">User Code : <b>{{this.data1[0].userCode}}</b></p>
        <p  style="border: 2px solid #ffd500;padding-top:12px;"  class="form-control mb-4">Date : <b>{{this.data1[0].date}}</b></p>
    </div>
</div>


<div *ngIf="trustvo" class="container">
        <br><br>  
       <form class="text-center border border-light p-5" action="#!">
       
           <p class="h4 mb-4">CMO Officer Feedback</p>
            <br>
           
        </form>
    <div class="border border-light p-5">
        <h3>{{record}}</h3>
    </div>
</div> -->