import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inversor-relation',
  template: `
    <p>
      inversor-relation works!
    </p>
  `,
  styles: []
})
export class InversorRelationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
