<div class="container ">
    <h4 class="text-center h4 mb-5" style="margin-top: 67px;"><b style="color:#FFD500;"> Collateral Assessment Form</b> – For Collateral Management Officer (CMO)</h4>
    <h4 class="text-center h4 mb-5" ><b style="color:#FFD500;">Collateral Assessment Form</b></h4>
 </div>
    




  <div class="container" style="margin-top: 0px; margin-bottom: 120px;">

        <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">

            <div style="display: block;" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                <p><b>Name</b> : {{data3.fullname}}</p>
                <p><b>CNIC</b> : {{data3.cnic}}</p>
                <p><b>Residential Address</b> : {{data3.currentaddress}}</p>
            </div>

    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
         Shroff Name :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/shroof.svg" style="width: 34px;">
            </div>
            <input class="form-control" name="shroffName" [(ngModel)]="data.shroffName" type="text" value="" placeholder="Shroff Name" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
            Shroff Certificate Number :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/date.svg" style="width: 34px;">
            </div>
            <input class="form-control" name="shroffCertificateNumber" type="text" [(ngModel)]="data.shroffCertificateNumber" placeholder="Shroff Certificate Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    

    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
        <label class="control-label " for="email">
            Nature Of Gold :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
              <img src="./assets/media/icons/gold-nature.svg" style="width: 34px;">
            </div>
            <select name="natureOfGold" [(ngModel)]="data.natureOfGold" class="form-control" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;resize: none;border-left: 1px #000!important;height: calc(2.6em + .75rem + 14px);">
                <option value="Bars">Bars</option>
                <option value="Biscuits">Biscuits</option>
                <option value="Jewelry">Jewelry</option>
                <option value="Ornaments">Ornaments</option>
            </select>
        </div>
    </div>

      
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/1.svg" style="width: 34px;">
            </div>
            <input class="form-control" name="details1" type="text" [(ngModel)]="data.details1" placeholder="1." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/2.svg" style="width: 34px;">
            </div>
            <input class="form-control" name="details2" type="text" [(ngModel)]="data.details2" placeholder="2." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/3.svg" style="width: 34px;">
            </div>
            <input class="form-control" name="details3" type="text" [(ngModel)]="data.details3" placeholder="3." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/4.svg" style="width: 34px;">
            </div>
            <input class="form-control" name="details4" type="text" [(ngModel)]="data.details4" placeholder="4." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-12 col-xl-12 pb-3 input-container">
        <label class="control-label " for="email">
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/5.svg" style="width: 34px;">
            </div>
            <input class="form-control" name="details5" type="text" [(ngModel)]="data.details5" placeholder="5." style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    
    
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
            Gross weight (grams) as per Shroff Certificate :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/groos-weight.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="number" name="grossWeight" [(ngModel)]="data.grossWeight" placeholder="Gross weight (grams) as per Shroff Certificate" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>    

    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
            Net Weight (grams) as per Shroff Certificate :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/gram-weight.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="number" name="netWeight" [(ngModel)]="data.netWeight" (change)="changeMarketValue()" placeholder="Net Weight (grams) as per Shroff Certificate" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
            Market Value as per Shroff Certificate 
            <p>&nbsp;</p>
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/market-value.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="number" name="marketValue" [(ngModel)]="data.marketValue" placeholder="Market Value as per Shroff Certificate" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
           Secured Packet (1) Number :
           <p>&nbsp;</p>
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/secured-packet.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="text" name="securedPacket1Number" [(ngModel)]="data.securedPacket1Number" placeholder="Secured Packet (1) Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
          Gross Weight (grams) By CMO :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/gram-weight.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="number" name="grossWeightCMO" [(ngModel)]="data.grossWeightCMO" placeholder="Gross Weight By CMO" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
           Secured Packet (2) Number :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/secured-packet.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="text" name="securedPacket2Number" [(ngModel)]="data.securedPacket2Number" placeholder="Secured Packet (2) Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
           Bar Code Number :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/bar-code.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="text" name="barCodeNumber" [(ngModel)]="data.barCodeNumber" placeholder="Secured Packet (2) Number" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
           CMO Name :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/name-icon.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="text" name="name" [(ngModel)]="data.name" placeholder="Name" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
           User Code :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/user-code.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="text" name="userCode" [(ngModel)]="data.userCode" placeholder="User Code" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>
    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
        <label class="control-label " for="email">
           Date :
        </label>
        <div class="input-group">
            <div class="input-group-addon">
            <img src="./assets/media/icons/date.svg" style="width: 34px;">
            </div>
            <input class="form-control" type="date" name="date" [(ngModel)]="data.date" placeholder="Date" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
        </div>
    </div>

    <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
            <label class="control-label " for="email">
            </label>
            <div class="input-group">
                <div class="input-group-addon addons1" >
                   <p style="position: relative; top: 8px; color: #000; padding: 0px 36px;">Picture 1 : </p>
                </div>
                <input class="form-control" type="file" name="image11" (change)="uploadImageCNIC2($event)"  style="padding: 15px;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;resize: none;border-left: 1px #000!important;height: calc(2.6em + .75rem + 14px);"/>
            </div>
        </div>

        <div style="display: block;" class="col-lg-6 col-xl-6 pb-3 input-container">
                <label class="control-label " for="email">
                </label>
                <div class="input-group">
                    <div class="input-group-addon addons1" >
                       <p style="position: relative; top: 8px; color: #000; padding: 0px 36px;">Picture 2 : </p>
                    </div>
                    <input class="form-control" type="file" name="image12" (change)="uploadImageResidenceUtilityBills($event)"  style="padding: 15px;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;resize: none;border-left: 1px #000!important;height: calc(2.6em + .75rem + 14px);"/>
                </div>
        </div>

        <br>
        <p class="col-lg-12" *ngIf="showPic1 == true || showPic2 == true"><b>If Uploaded Pictures</b></p>
        <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;"  *ngIf="showPic1">
                <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Picture 1 : <br><br>
                    <a style="color:black" href="{{baseURL}}/{{data.picture1}}">Download and View Picture 1</a></p>
                <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.picture1}}" />
        </div>
        <br>
        <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;" *ngIf="showPic2">
                    <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Picture 2 : <br><br>
                        <a style="color:black" href="{{baseURL}}/{{data.picture2}}">Download and View Picture 2</a></p>
                    <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.picture2}}" />
        </div>
        <br>
        <div class="col-lg-12" *ngIf="showPic1 == false && showPic2 == false">
                <p class="col-lg-12" ><b>No Uploaded Pictures</b></p>
        </div>




    <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;">
         <button class="btn btn-lg" (click)="submit()" type="submit" style="border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">Submit</button>
    </div>


      </div>
</div>
  



<!-- 

<div class="container">
    <br><br>  
   <form class="text-center border border-light p-5" action="#!">
   
       <p class="h4 mb-4">Collateral Assessment Form – For Collateral Management Officer (CMO)</p>
        <br>
        <p><span  class="h5 mb-4" style="color:#ffd500"><b>Collateral Assessment Form</b></span> </p>
       <br>
       <div style="border-radius: 5px; background: #ffd500;" class="input-container">
            <div style="padding-top: 10px; width: 4%;">
                <img style="width: 25px;" src="assets/images/icon/shname.svg" />
            </div>
            <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="shroffName" class="form-control mb-4" [(ngModel)]="data.shroffName" placeholder="Shroff Name">
        </div>
       
        <div style="border-radius: 5px; background: #ffd500;" class="input-container">
                <div style="padding-top: 10px; width: 4%;">
                    <img style="width: 25px;" src="assets/images/icon/shcert.svg" />
                </div>
                <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="shroffCertificateNumber" class="form-control mb-4" [(ngModel)]="data.shroffCertificateNumber" placeholder="Shroff Certificate number">
        </div>
       <label style="float: left;">Nature of Gold :</label>
       <div style="border-radius: 0;width: 55px; -webkit-appearance: none; -moz-appearance: none; appearance: none; background-size: 9px; background-repeat: no-repeat; background-position: 12% 15px;">
        <select name="natureOfGold" style="border: 2px solid #ffd500;background: transparent ;border-width: 0 0 1px 0; font-size: 1rem;"  [(ngModel)]="data.natureOfGold">
          <option value="Bars">Bars</option>
          <option value="Biscuits">Biscuits</option>
          <option value="Jewelry">Jewelry</option>
          <option value="Ornaments">Ornaments</option>
        </select>
       </div>
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="details1" class="form-control mb-4" [(ngModel)]="data.details1" placeholder="1.">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="details2" class="form-control mb-4" [(ngModel)]="data.details2" placeholder="2.">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="details3" class="form-control mb-4" [(ngModel)]="data.details3" placeholder="3.">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="details4" class="form-control mb-4" [(ngModel)]="data.details4" placeholder="4.">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="details5" class="form-control mb-4" [(ngModel)]="data.details5" placeholder="5.">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="grossWeight" id="grossWeight" class="form-control mb-4" [(ngModel)]="data.grossWeight" placeholder="Gross weight (grams) as per Shroff Certificate">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="netWeight" id="netWeight" class="form-control mb-4" [(ngModel)]="data.netWeight" placeholder="Net Weight (grams) as per Shroff Certificate">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="marketValue" id="marketValue" class="form-control mb-4" [(ngModel)]="data.marketValue" placeholder="Market Value as per Shroff Certificate">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="securedPacket1Number" [(ngModel)]="data.securedPacket1Number" class="form-control mb-4" placeholder="Secured Packet (1) number">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="grossWeightCMO" [(ngModel)]="data.grossWeightCMO" class="form-control mb-4" placeholder="Gross weight by CMO">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="securedPacket2Number" [(ngModel)]="data.securedPacket2Number" class="form-control mb-4" placeholder="Secured Packet (2) number">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="barCodeNumber" [(ngModel)]="data.barCodeNumber" class="form-control mb-4" placeholder="BAR Code Number">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="name" [(ngModel)]="data.name" class="form-control mb-4" placeholder="Name">
       <br>
       <input type="text" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="userCode" [(ngModel)]="data.userCode" class="form-control mb-4" placeholder="User Code">
       <br>
       <input type="date" style="border: 2px solid #ffd500;margin-bottom: 0px !important;" name="date" [(ngModel)]="data.date" class="form-control mb-4" >
      
      
    
      
      
       <br><br>
       <a class="btn btn-default" (click)="submit()">Submit</a>
       <br><br>
   
   </form>
   <br><br> 
   </div> -->