<div class="container" style="margin-top: 80px; margin-bottom: 120px;">
    <h1 class="text-center mb-2" style="margin-top: 67px;"><b style="color:#FFD500;">Loan </b> Application Form (LAF)</h1>
    <h3 class="text-center mb-5" style="margin-top: 67px;color:#FFD500; font-weight: bold;"> Documents Uploading</h3>
    
    <div *ngIf="recommend">
            <form action="">
                    <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">
            
                <div class="col-lg-12 col-xl-12 pb-3 input-container">
                </div>
             
            
                <h4>Uploaded Documents : </h4>
                <br><br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Front CNIC <br><br><a style="color:black" href="{{baseURL}}/{{data.cnicFront}}">Download and View Front CNIC</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicFront}}" />
                        
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Back CNIC <br><br><a style="color:black" href="{{baseURL}}/{{data.cnicBack}}">Download and View Back CNIC</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.cnicBack}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Residence Utility Bills <br><br><a style="color:black" href="{{baseURL}}/{{data.residenceUtilityBills}}">Download and View Residence Utility Bills</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.residenceUtilityBills}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Current Salary Slip <br><br><a style="color:black" href="{{baseURL}}/{{data.currentSalarySlip}}">Download and View Current Salary Slip</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.currentSalarySlip}}" />
                        
                </div>
                
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Job Certificate <br><br><a style="color:black" href="{{baseURL}}/{{data.jobCertificate}}">Download and View Job Certificate</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.jobCertificate}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Experience Letter <br><br><a style="color:black" href="{{baseURL}}/{{data.experienceLetter}}">Download and View Experience Letter</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.experienceLetter}}" />
                </div>

                  </div>
               </form>
    </div>

    <div *ngIf="recommend2">
            <form action="">
                    <div class="col-lg-10 col-xl-10 col-md-10 col-10 offset-1 offset-lg-1 offset-xl-1 offset-sm-0 col-sm-12  offset-md-1 row" id="form-div" style=" padding: 40px 1px; background: #FFFFDD; border: 1px solid #ffffb73d;border: 1px solid #ffff00;border-radius: 10px;">
            
                <div class="col-lg-12 col-xl-12 pb-3 input-container">
                </div>
             
            
     
            
            
                <br><br><br>
                <h4>Uploaded Documents : </h4>
                <br>
                
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Front CNIC <br><br><a style="color:black" href="{{baseURL}}/{{data.cnicFront}}">Download and View Front CNIC</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicFront}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Back CNIC <br><br><a style="color:black" href="{{baseURL}}/{{data.cnicBack}}">Download and View Back CNIC</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicBack}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Residence Utility Bills <br><br><a style="color:black" href="{{baseURL}}/{{data.residenceUtilityBills}}">Download and View Residence Utility Bills</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.residenceUtilityBills}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Business Utility Bills <br><br><a style="color:black" href="{{baseURL}}/{{data.residenceBusinessBills}}">Download and View Business Utility Bills</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.residenceBusinessBills}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Proof of Business <br><br><a style="color:black" href="{{baseURL}}/{{data.proofOfBusiness}}">Download and View Proof of Business</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.proofOfBusiness}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Proof of Business Income <br><br><a style="color:black" href="{{baseURL}}/{{data.proofOfBusinessIncome}}">Download and View Proof of Business Income</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.proofOfBusinessIncome}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Finance Agreement 1 <br><br><a style="color:black" href="{{baseURL}}/{{data.NTN}}">Download and View Finance Agreement 1</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.NTN}}" />
                </div>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Finance Agreement 1 <br><br><a style="color:black" href="{{baseURL}}/{{data.SECP}}">Download and View Finance Agreement 2</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.SECP}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Facility Letter <br><br><a style="color:black" href="{{baseURL}}/{{data.facilityLetter}}">Download and View Facility Letter</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.facilityLetter}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Loan Application 1 <br><br><a style="color:black" href="{{baseURL}}/{{data.loanApp1}}">Download and View Loan Application 1</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.loanApp1}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Loan Application 2 <br><br><a style="color:black" href="{{baseURL}}/{{data.loanApp2}}">Download and View Loan Application 2</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.loanApp2}}" />
                </div>

                
                  </div>
               </form>
    </div>
    <br><br>
    
</div>