<div class="page-header1" style="linear-gradient:(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(../images/cover-3.jpg) no-repeat center">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <h2 class="display-4" style="color: aliceblue;" translate>UN1074</h2>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="wrapper-content bg-white ">
        <div class="about-section pinside40">
            <div style="margin-top: -35px;">
              <div class="row">
                <div class="mb30 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h2 style="color: #0E0057;" translate>UN76</h2>
                  <p style="color: #0E0057;" translate>UN77</p>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h2 style="color: #0E0057;" translate>UN78</h2>
                  <p style="color: #0E0057;" translate>UN79</p>
                </div>
                <div class="mb30 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h2 style="color:#0E0057;" translate>UN1001</h2>
                  <ul>
                    <li style="color: #0E0057;" translate>UN1002</li>
                    <li style="color: #0E0057;" translate>UN1003</li>
                    <li style="color: #0E0057;" translate>UN1004</li>
                    <li style="color: #0E0057;" translate>UN1005</li>
                  </ul>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h2 style="color: #0E0057;" translate>UN177</h2>
                  <p style="color: #0E0057;" translate>UN178</p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
