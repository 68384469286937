<div class="container">
    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-10 col-md-8 col-12 offset-xl-3 bg-white offset-md-2 offset-lg-3 offset-sm-1"style="padding: 23px;margin-top: 120px;  background: #FFFFDD!important;">
        <h2 style="padding: 20px 0px;">Add Gold Rate</h2>
        <form action="">
            <div style="display: block;" class="col-lg-12 col-xl-12 pb-4 input-container">
                <div class="input-group">
                    <div class="input-group-addon">
                    <img src="assets/images/icon/cnic.svg" style="width: 30px;">
                    </div>
                    <input class="form-control" name="goldrate" type="number" [(ngModel)]="data.goldrate" placeholder="Gold Price" style="border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;padding: 19px 30px;resize: none;border-left: 1px #000!important;" />
                </div>
            </div>
            <div style="display: block;" class="col-lg-12 col-xl-12 pb-4 input-container">
                <div class="input-group">
                    <div class="input-group-addon">
                    <img src="./assets/media/media/icons/loan.svg" style="width: 30px;">
                    </div>
                        <select class="form-control" name="type" [(ngModel)]="data.type" style="height: calc(2.25rem + 12px) !important;border: 2px solid #808080!important;border-top-left-radius:0px!important;border-bottom-left-radius:0px!important;resize: none;border-left: 1px #000!important;">
                            <option value="gram">Gram</option>
                            <option value="tola">Tola</option>
                        </select>
                    </div>
            </div>
            <br>
            <p style="color:red;padding-bottom: 10px;float: left;" class="h5">{{error}}</p>
            <br>
            <p style="color:green;padding-bottom: 10px;float: left;" class="h5">{{sucess}}</p>
            <br>
            <div class="sign-button text-center">
                <button class="btn btn" (click)="submit()" style="background:#F3CB00; padding: 10px 51px;">Add Gold Rate</button>
            </div>
            
            
        </form>
    </div>
</div> 
<br><br>