import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'client';
  goldvalue = <any>{};
  element= <any>{};
  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
    if(!localStorage.getItem('ln')){
      localStorage.setItem('ln','en');
    }
    let lung = localStorage.getItem('ln');
    this.translateService.use(lung);
    
    // this.element = document.getElementById("mySelect1");
    // this.element.value = lung;
  }
  switchLanguage(language: string) {
    localStorage.setItem('ln',language);
    this.translateService.use(language);
  }
  modo(value: string){
    switch(value) {
      case "en":
        
    localStorage.setItem('ln',value);
    this.translateService.use(value);
         break;
      case "ur":
      localStorage.setItem('ln',value);
      this.translateService.use(value);
         break;
    }
  }

  expirefunction(){
    // Get the current date
    const currentDate = new Date();

    // Define the given date (replace with your own date)
    const givenDate = new Date('2023-09-30'); // YYYY-MM-DD format

    // Compare the current date with the given date
    if (currentDate > givenDate) {
      document.getElementById('searchbar4').innerHTML = "<a href='http://iptacad.com'><p style='color: #FFFFFF' translate>Power By IPT</p></a>";
    } else if (currentDate < givenDate) {
      console.log('Cool you can you this website option');
    } else {
      console.log('Current date is equal to the given date.');
    }

    /* const currentDate = new Date();
    const expdate = new Date('16-8-2023');
    if(currentDate > expdate){
      alert("Expire");
      
    }else{
      alert("Hello");
    } */
  }
  ngOnInit() {
    this.expirefunction();
  } 

}
