<div class="container" style="margin-top: 80px; margin-bottom: 120px;">
        <h1 class="text-center mb-2" style="margin-top: 67px;"><b style="color:#FFD500;">Loan </b> Application Form (LAF)</h1>
        <div class="row">
                        <div style="display: block;" *ngIf="data1[0].csoId && data1[0].csoName" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                                <p><b>CSO Name </b> : {{data1[0].csoName}}</p>
                                <p><b>CSO ID </b> : {{data1[0].csoId}}</p>
                        </div>
                        <div style="display: block;" *ngIf="data1[0].boId && data1[0].boName" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                                <p><b>BO Name </b> : {{data1[0].boName}}</p>
                                <p><b>BO ID </b> : {{data1[0].boId}}</p>
                        </div>
                        <div style="display: block;" *ngIf="data1[0].bsId && data1[0].bsName" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                                <p><b>BS Name </b> : {{data1[0].bsName}}</p>
                                <p><b>BS ID </b> : {{data1[0].bsId}}</p>
                        </div>
                </div>
                <br><br><div style="display: block;" class="text-center col-lg-12 col-xl-12 pb-3 input-container">
                        <p><b>Customer Name</b> : {{data1[0].fullname}}</p>
                        <p><b>CNIC</b> : {{data1[0].cnic}}</p>
                    </div>
                    <br><br>
        <h3 class="text-center mb-5" style="margin-top: 67px;color:#FFD500; font-weight: bold;"> Documents Uploading</h3>
        
        <div *ngIf="recommend">
                 <br><br><br>
                    <h4>Uploaded Documents : </h4>
                    <br><br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Front CNIC <br><br><a style="color:black" href="{{baseURL}}/{{data.cnicFront}}">Download and View Front CNIC</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicFront}}" />
                        
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Back CNIC <br><br><a style="color:black" href="{{baseURL}}/{{data.cnicBack}}">Download and View Back CNIC</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.cnicBack}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Residence Utility Bills <br><br><a style="color:black" href="{{baseURL}}/{{data.residenceUtilityBills}}">Download and View Residence Utility Bills</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.residenceUtilityBills}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Current Salary Slip <br><br><a style="color:black" href="{{baseURL}}/{{data.currentSalarySlip}}">Download and View Current Salary Slip</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.currentSalarySlip}}" />                        
                </div>                
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Job Certificate <br><br><a style="color:black" href="{{baseURL}}/{{data.jobCertificate}}">Download and View Job Certificate</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.jobCertificate}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Experience Letter <br><br><a style="color:black" href="{{baseURL}}/{{data.experienceLetter}}">Download and View Experience Letter</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.experienceLetter}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Finance Agreement 1 <br><br><a style="color:black" href="{{baseURL}}/{{data.NTN}}">Download and View Finance Agreement 1</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.NTN}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Finance Agreement 2 <br><br><a style="color:black" href="{{baseURL}}/{{data.SECP}}">Download and View Finance Agreement 2</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.SECP}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Facility Letter <br><br><a style="color:black" href="{{baseURL}}/{{data.facilityLetter}">Download and View Facility Letter</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.facilityLetter}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Loan Application 1 <br><br><a style="color:black" href="{{baseURL}}/{{data.loanApp1}}">Download and View Loan Application 1</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.loanApp1}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Loan Application 2 <br><br><a style="color:black" href="{{baseURL}}/{{data.loanApp2}}">Download and View Loan Application 2</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.loanApp2}}" />
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;" *ngIf="roleBs">
                        <button class="btn btn-lg" type="submit" style="padding: 15px;border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">
                            <a href="bo2" style="color: black">Back</a>
                        </button>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;" *ngIf="roleCso">
                        <button class="btn btn-lg" type="submit" style="padding: 15px;border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">
                            <a href="cso" style="color: black">Back</a>
                        </button>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;" *ngIf="roleVo">
                        <button class="btn btn-lg" type="submit" style="padding: 15px;border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">
                            <a href="vo" style="color: black">Back</a>
                        </button>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;" *ngIf="roleBo">
                        <button class="btn btn-lg" type="submit" style="padding: 15px;border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">
                            <a href="branchOfficer" style="color: black">Back</a>
                        </button>
                    </div>

        </div>

        <div *ngIf="recommend2">
                
                
                    <br><br><br>
                    <h4>Uploaded Documents : </h4>
                    <br>
                    
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Front CNIC <br><br><a style="color:black" href="{{baseURL}}/{{data.cnicFront}}">Download and View Front CNIC</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicFront}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Back CNIC <br><br><a style="color:black" href="{{baseURL}}/{{data.cnicBack}}">Download and View Back CNIC</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicBack}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Residence Utility Bills <br><br><a style="color:black" href="{{baseURL}}/{{data.residenceUtilityBills}}">Download and View Residence Utility Bills</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.residenceUtilityBills}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Business Utility Bills <br><br><a style="color:black" href="{{baseURL}}/{{data.residenceBusinessBills}}">Download and View Business Utility Bills</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.residenceBusinessBills}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Proof of Business <br><br><a style="color:black" href="{{baseURL}}/{{data.proofOfBusiness}}">Download and View Proof of Business</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.proofOfBusiness}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Proof of Business Income <br><br><a style="color:black" href="{{baseURL}}/{{data.proofOfBusinessIncome}}">Download and View Proof of Business Income</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.proofOfBusinessIncome}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Finance Agreement 1 <br><br><a style="color:black" href="{{baseURL}}/{{data.NTN}}">Download and View Finance Agreement 1</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.NTN}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Finance Agreement 2 <br><br><a style="color:black" href="{{baseURL}}/{{data.SECP}}">Download and View Finance Agreement 2</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.SECP}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Facility Letter <br><br><a style="color:black" href="{{baseURL}}/{{data.facilityLetter}">Download and View Facility Letter</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.facilityLetter}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Loan Application 1 <br><br><a style="color:black" href="{{baseURL}}/{{data.loanApp1}}">Download and View Loan Application 1</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.loanApp1}}" />
                </div>
                <br>
                <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                        <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Loan Application 2 <br><br><a style="color:black" href="{{baseURL}}/{{data.loanApp2}}">Download and View Loan Application 2</a></p>
                        <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.loanApp2}}" />
                </div>
                


                <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;" *ngIf="roleBs">
                        <button class="btn btn-lg" type="submit" style="padding: 15px;border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">
                            <a href="bo2" style="color: black">Back</a>
                        </button>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;" *ngIf="roleCso">
                        <button class="btn btn-lg" type="submit" style="padding: 15px;border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">
                            <a href="cso" style="color: black">Back</a>
                        </button>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;" *ngIf="roleVo">
                        <button class="btn btn-lg" type="submit" style="padding: 15px;border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">
                            <a href="vo" style="color: black">Back</a>
                        </button>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 pt-5 buttons" style="text-align: center;" *ngIf="roleBo">
                        <button class="btn btn-lg" type="submit" style="padding: 15px;border-radius: 11px;margin: 10px;padding: 10px 96px;background: #FFD500;">
                            <a href="branchOfficer" style="color: black">Back</a>
                        </button>
                    </div>
                    
        </div>
          
        
</div>

<!-- 

<div class="container">
        <br><br>  
       <form class="text-center border border-light p-5" action="#!">
       
           <p class="h4 mb-4">Loan Application Form (LAF)</p>
            <br>
            <p><span  class="h5 mb-4" style="color:#ffd500"><b>Documents Uploading</b></span></p>
           <br>
           <br>
            <div *ngIf="recommend">
                       
                    <br><br><br>
                    <h4>Uploaded Documents : </h4>
                    <br><br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Front CNIC</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicFront}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Back CNIC</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.cnicBack}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Residence Utility Bills</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.residenceUtilityBills}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Current Salary Slip</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.currentSalarySlip}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Job Certificate</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.jobCertificate}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Experience Letter</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;"  src="{{baseURL}}/{{data.experienceLetter}}" />
                    </div>
            </div>
            <br>
            <div *ngIf="recommend2">
                 
                   
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Front CNIC</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicFront}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Back CNIC</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.cnicBack}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Residence Utility Bills</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.residenceUtilityBills}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Business Utility Bills</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.residenceBusinessBills}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Proof of Business</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.proofOfBusiness}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">Proof of Business Income</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.proofOfBusinessIncome}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">SECP registration, if registered</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.SECP}}" />
                    </div>
                    <br>
                    <div class="col-lg-12" style="display: flex; width: 100%; margin-bottom: 15px;">
                            <p style="height: calc(1.5em + .75rem + 7px);background: #00000029; padding: 9px; min-width: 35%; color: black;">NTN number, if filler</p>
                            <img style="margin-left: 60px; width: 258px; height: 135px;" src="{{baseURL}}/{{data.NTN}}" />
                    </div>

                    
            </div>
            
           <br><br>
           <br><br>
           <p style="color:green;float: left;" class="h5">{{sucess}}</p>

       </form>
       <br><br> 
       </div> -->