<marquee onmouseenter="this.stop()" onmouseout="this.start();" direction="left" style="background-color: #1e1854;; color: #ffc91a">
  <!--Loans Disbursed <b>1,017</b>, Volume Disbursed <b>PKR 194 million</b>, Females <b>36%</b>, Males <b>64%</b>, Fresh Loans <b>65%</b>, Repeat Loans <b>18%</b> and Rollover Loans <b>17%</b> - as of Jul’23-->
  <!-- Loans Disbursed <b>1,501</b>, Volume Disbursed <b>PKR 315 million</b>, Females <b>35%</b>, Males <b>65%</b>, Fresh Loans <b>73%</b>, Repeat Loans <b>14%</b> and Rollover Loans <b>13%</b>, ZERO Delinquency-as of Dec'23 -->
  <!-- Disbursement: <b>1,862 loan, PKR 419 million</b>, Outstanding Portfolio: <b>1,185 loans, PKR 299 million,</b> Female Customers: <b>35%</b>, Fresh Loans <b>73%</b>, Repeat Loans <b>14%</b> and Rollover Loans <b>13%</b>, ZERO Delinquency-as of Jan'24 -->
  Disbursement: <b>2,307 loans, PKR 536 million,</b> Outstanding Portfolio: <b>1,476 loans, PKR 386 million,</b> Female Customers: <b>35%,</b> Fresh Loans <b>80%,</b> Repeat Loans <b>11%</b> and Rollover Loans <b>9%,</b> ZERO Delinquency as of Mar'24
</marquee>
<div class="container-fluid" style="padding: 0px; margin-top: -6px;">
  <div class="row ml-0 mr-0">
    <div class="col-md-7 pl-0 pr-0">

      <div class="container pl-0 pr-0" style="margin-top: 50px;">
        <div class="banner_heading" id="banner_heading">
          <!-- font-family: 'Gilroy' !important; -->
          <!-- <h1 class="companystyle_text heading_partThree" style="color: black; text-align: center; font-weight: 600; ">Hassle Free</h1>
          <h1 class="companystyle_text" style="color:#f5a11f;text-align: center;font-size: 40px !important;  font-weight: 600; ">Gold-backed Loan </h1>
          <h1 class="companystyle_text" style="color: black;text-align: center;font-weight: 600; "> In Collaboration with Our Partner's</h1> -->
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <h3 class="display-4" style="color: #1e1854; text-align: center; font-size: 40px;">GoldFin Offers</h3>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <h2 style="color: #ffc91a; text-align: center; font-weight: 600; ">Hassle Free Gold Loan</h2>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <p class="lead" style="color: #1e1854; text-align: center;font-weight: 400; ">Lowest markup at 1.9% per month (23% per annum)</p>
            </div>
          </div>
        </div><br/>
        <!-- <div class="bank-brand">-->
          <div class="container pl-0 pr-0" style="margin-top:67px; padding:0px; width: 100%;" id="partners"> 
            <h3 style="color:#1e1854; margin-left: 10px;">Our Partners</h3>
            <div class="row" style="margin: 0px; padding:10px; ">
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding: 0px; padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/Escorts-1.png" alt="Escorts" style="width: 100px; height:37px">
              </div>
              <!--<div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/tez-1.png" alt="Tez" style="width: 66px; height:37px">
              </div>-->
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/damen.png" alt="Damen" style="width: 50px; height:37px">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/rcdp-1.png" alt="RDCP" style="width: 100px; height:37px">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/Taleem.png" alt="Taleem" style="width: 90px; height:37px">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding: 0px; padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/easy-1.png" alt="Easypaisa" style="width: 100px; height:37px">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding: 0px 0px 10px 0px">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/tasdeeq-1.png" alt="Tadessq" style="width: 100px; height:37px">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/efu-1.png" alt="Efu" style="width: 50px; height:37px">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/asia-1.png" alt="AsiaInsurance" style="width: 100px; height:37px">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding-bottom: 10px;">
                <img loading="lazy" id="" class="img img-fluid" src="../../assets/media/icons/png/NADRA.png" alt="NADRA" style="width: 68px; height:37px">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-3" style="padding-bottom: 10px;"></div>
            </div>
          </div>
        <!--</div> -->
        <br>
        <div class="container" style="display:inline-block;"> 
          <div class="row justify-content-center">
            
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 pl-0 pr-0" style="padding: 0px;">
      <div class="slider" id="slider">
        <!-- slider -->
        <div class="slider-img">
          <img src="assets/images/bangle_display-header-720x300.jpg" alt="Borrow - Loan Company Website Template" class="img-fluid">
          <div class="slider-captions">
            <!-- slider-captions -->
            <h1 style="font-size: 24px;" class="slider-title" translate>UN1</h1>
            <p style="width: 330px; color:#0E0057;font-weight: 600;text-shadow: 1px 1px 2px #a07803, 0 0 25px #ffc107, 0 0 5px #ffc107;" class="slider-text d-block d-xl-block d-lg-block d-sm-block" translate>
              UN2
            </p>
            <br>
            <p class="slider-text d-block d-xl-block d-lg-block d-sm-block malikq" style="width: 350px;">
              <strong class="text-highlight" translate>&nbsp;&nbsp;&nbsp;UN3</strong><br>
              <strong class="text-highlight" translate>&nbsp;&nbsp;&nbsp;UN4</strong><br>
              <strong class="text-highlight" translate>&nbsp;&nbsp;&nbsp;UN5</strong><br>
              <strong class="text-highlight" translate>&nbsp;&nbsp;&nbsp;UN6</strong><br>
            </p>
            <a href="loancalculator" class="btn btn-default" translate>UN7</a>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <!-- /.slider-captions -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="slider-img">
            <img src="assets/images/slider-2.jpg" alt="Borrow - Loan Company Website Template" class="img-fluid">
            <div class="lkj slider-captions" id="aka" >
              <!-- slider-captions -->
              <h1 style="font-size: 24px;" class="slider-title" translate>UN8<strong class="text-highlight"></strong> </h1>
                  <br>
<!--              <p style="color:#0E0057;font-weight: 600;" class="slider-text d-block d-xl-block d-lg-block d-sm-block" >We offer instant loan on gold collateral through innovative, digital and tested solutions with value added features.-->
<!--              </p>-->
              <br>
              <p class="slider-text d-block d-xl-block d-lg-block d-sm-block malikq" style="width: 400px;">
                <strong class="clk text-highlight" translate>UN9</strong><br>
<!--                <strong class="text-highlight" >&nbsp;&nbsp;&nbsp;Salary Loan</strong><br>-->
                <strong class="clk text-highlight" translate>UN10</strong><br>
              </p>
              <a href="faq" class="btn btn-default" translate>UN11</a> 
            </div>
            <div class="container">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  
                  <!-- /.slider-captions -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="slider-img">
            <img src="assets/images/slider-3.jpg" alt="Borrow - Loan Company Website Template" class="">
            <div class="container">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="pop slider-captions">
                    <!-- slider-captions -->
                    <h1 style="font-size: 24px;" class="slider-title" translate>UN12</h1>
                    <p style="color:#0E0057;font-weight: 600;text-shadow: 1px 1px 2px #a07803, 0 0 25px #ffc107, 0 0 5px #ffc107;" class="slider-text d-block d-xl-block d-lg-block d-sm-block" translate>UN64</p>
                    <br>
                    <p class="slider-text d-block d-xl-block d-lg-block d-sm-block malikq" style="width: 400px;">
                      <strong class="text-highlight" translate>&nbsp;&nbsp;&nbsp;UN13</strong><br>
                      <strong class="text-highlight" translate>&nbsp;&nbsp;&nbsp;UN14</strong><br>
                      <strong class="text-highlight" translate>&nbsp;&nbsp;&nbsp;UN15 </strong><br>
                    </p>
                    <a href="loancalculator" class="btn btn-default " translate>UN16</a> </div>
                  <!-- /.slider-captions -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="hero_strip"></div>
<div class="rate-table">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
        <div class="rate-counter-block">
          <div class="icon rate-icon"><img style="margin-top: 0px;" src="assets/images/-a_03.png" alt="Borrow - Loan Company Website Template" class="icon-svg-1x"></div>
          <div class="rate-box">
            <small class="rate-title" style="color: #0E0057;" translate>UN17</small>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
        <div class="lkl rate-counter-block">
          <div class="icon rate-icon  "> <img style="margin-top: 0px;" src="assets/images/b_07.png" alt="Borrow - Loan Company Website Template" class="icon-svg-1x"></div>
          <div class="rate-box">
            <small class="rate-title" style="color: #0E0057;" translate>UN18</small>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
        <div class="rate-counter-block" >
          <div class="icon rate-icon  "> <img style="margin-top: 1px;" src="assets/images/last-ic_14.png" alt="Borrow - Loan Company Website Template" class="icon-svg-1x"></div>
          <div class="rate-box">
            <small class="rate-title" style="color: #0E0057;" translate>UN19</small>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
        <div class="lkl rate-counter-block">
          <div class="icon rate-icon  "> <img style="margin-top: -2px;" src="assets/images/last-ic_10.png" alt="Borrow - Loan Company Website Template" class="icon-svg-1x"></div>
          <div class="rate-box">
            <small class="rate-title" style="color: #0E0057;" translate>UN20</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section-space80">
  <div class="container">
    <div class="row">
      <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
        <div class="mb60 text-center section-title">
          <!-- section title start-->
          <h1 style="color: #0E0057;" translate>UN21</h1>
          <p style="color: #0E0057;" translate>UN22</p>
        </div>
        <!-- /.section title start-->
      </div>
    </div>
    <div class="row">
      <div class="service" id="service" >
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="bg-white pinside40 service-block outline mb30" style="height: 460px;">
            <div class="icon mb40"> <img src="assets/images/emergencyloan.jpg" class="icon-svg-2x"> </div>
            <h2><a href="#" class="title" style="color: #0E0057;" translate>UN23</a></h2>
            <p style="color: #0E0057;" translate>UN24</p>
            <a style="color: #0E0057; font-weight: 500; cursor: pointer;padding-bottom: 4px; border-bottom: 2px solid #0E0057; text-decoration: none;" href="/emergencyloan" class="btn-link" translate >UN25</a> </div>
        </div>
<!--        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">-->
<!--          <div class="bg-white pinside40 service-block outline mb30" style="height: 435px;">-->
<!--            <div class="icon mb40"> <img src="assets/images/salaryloan.jpg" alt="Borrow - Loan Company Website Template" class="icon-svg-2x"></div>-->
<!--            <h2><a href="#" style="color: #0E0057;" class="title" >Salary Loan</a></h2>-->
<!--            <p style="color: #0E0057;">All salaried employees aged 20 – 64 years, having valid CNIC, who can offer gold (bars/ ornaments / jewelry) as Collateral to avail salary loan to meet their domestic and emergent needs.</p>-->
<!--            <a href="/salaryloan" style="color: #0E0057; font-weight: 500; cursor: pointer;padding-bottom: 4px; border-bottom: 2px solid #0E0057; text-decoration: none;" class="btn-link"  onclick="myFunction1()" id="myBtn12" class="btn-link" >Read More</a> </div>-->
<!--        </div>-->
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="bg-white pinside40 service-block outline mb30" style="height: 460px;">
            <div class="icon mb40"> <img src="assets/images/businessloan.jpg" alt="Borrow - Loan Company Website Template" class="icon-svg-2x"></div>
            <h2><a href="#" style="color: #0E0057;" class="title" translate>UN26</a></h2>
            <p style="color: #0E0057;" translate>UN27<span id="dots" > </span><span id="more" translate> UN28</span></p>
            <a style="color: #0E0057; font-weight: 500; cursor: pointer;padding-bottom: 4px; border-bottom: 2px solid #0E0057; text-decoration: none;" href="/businessloan" class="btn-link"  onclick="myFunction()" id="myBtn" translate>UN25</a> </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-white section-space80">
  <div class="container">
    <div class="row">
      <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
        <div class="mb100 text-center section-title">
          <!-- section title start-->
          <h1 translate>UN29</h1>
        </div>
        <!-- /.section title start-->
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="bg-white hamza pinside40 number-block outline mb60 bg-boxshadow" style="height:419px;background: #ffc107!important">
          <img class="circle" src="assets/images/icon_03.png">
          <h3 style="color: #0E0057;" class="number-title" translate>UN30</h3>
          <ul><li style="color: #0E0057;text-align: left;" translate>UN31</li>
            <li style="color: #0E0057;text-align: left;" translate>UN32</li>
            <li style="color: #0E0057;text-align: left;" translate>UN33</li>
            <li style="color: #0E0057;text-align: left;" translate>UN34</li>
          </ul>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div id="malik23" class="bg-white pinside40 number-block outline mb60 bg-boxshadow" style="height:419px;background: #ffc107!important">
          <img class="circle" src="assets/images/2_07.png">
          <h3 style="color: #0E0057;" class="number-title" translate>UN35</h3>
          <p style="color: #0E0057;text-align: left;" translate>UN36</p>
          <p style="color: #0E0057;text-align: left;" translate>UN37</p>
          <ul>
          <span id="dots1"> &nbsp;</span><span id="more1">
            <li style="color: #0E0057;text-align: left;" translate>UN38</li>
            <li style="color: #0E0057;text-align: left;" translate>UN39</li>
            <li style="color: #0E0057;text-align: left;" translate>UN40</li>
            <li style="color: #0E0057;text-align: left;" translate>UN41</li>
            <li style="color: #0E0057;text-align: left;" translate>UN42</li>
            <li style="color: #0E0057;text-align: left;" translate>UN43</li>
            <li style="color: #0E0057;text-align: left;" translate>UN44</li>
            <li style="color: #0E0057;text-align: left;" translate>UN45</li>
          </span>
          </ul>
          <a style="color: #0E0057; font-weight: 500; cursor: pointer;padding-bottom: 4px; border-bottom: 2px solid #0E0057; text-decoration: none;" class="btn-link"  onclick="myFunction1()" id="myBtn1" class="btn-link" translate>UN46</a>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="bg-white hamza pinside40 number-block outline mb60 bg-boxshadow" style="height:419px;background: #ffc107!important" >
          <img class="circle" src="assets/images/34_10.png">
          <h3 style="color: #0E0057;" class="number-title" translate>UN47</h3>
          <p style="color: #0E0057;text-align: left;" translate>UN48</p>
            <ul style="margin-top: -16px;">
              <li style="color: #0E0057;text-align: left;" translate>UN49</li>
              <li style="color: #0E0057;text-align: left;" translate>UN50</li>
            </ul>
        </div>
      </div>
    </div>
    <div class="row">
    </div>
  </div>
</div>

<div class="section-space80">
  <div class="container">
    <div class="row">
      <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
        <div class="mb60 text-center section-title">
          <!-- section title start-->
          <h1 translate>UN51</h1>
        </div>
        <!-- /.section title start-->
      </div>
    </div>
    <div class="row">
      <div class="service1" id="service">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="bg-white pinside60 number-block outline" style="height: 351px;">
            <div class="mb20"><img src="assets/images/bew_03.png" /></div>
            <h3 style="padding-top: 45px;color: #0E0057;" translate>UN52</h3>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="bg-white pinside60 number-block outline" style="height: 351px;">
            <div class="mb20"><img src="assets/images/DigitalandTransparentProcessing.png" /></div>
            <h3 style="padding-top: 30px;color: #0E0057;" translate>UN53</h3>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="bg-white pinside60 number-block outline" style="height: 351px;">
            <div class="mb20"><img src="assets/images/CollateralSecuredwithBank.png" style="width: 70px;height: 50px;" /></div>
            <h3 style="padding-top: 41px;color: #0E0057;" translate>UN54</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="bg-white section-space80">
  <div class="container">
    <div class="row">
      <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
        <div class="mb60 text-center section-title">
          <!-- section title start-->
          <h1 translate>UN55</h1>
        </div>
        <!-- /.section title start-->
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div class="bg-white pinside60 number-block outline" style="background: #ffc107!important;height: 370px;">
                <div class="mb40"><img style="height: 75px;" src="assets/images/a_18.png" /></div>
                <h2 class="capital-title" style="color: #0E0057" translate>UN56</h2>
                <p style="padding-top: 25px;" translate>UN57</p>
                <a href="#" class="btn-link" style="padding-top: 19px;color: #0E0057;border-bottom: 2px solid #0E0057;" translate>UN56</a> </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div class="bg-white pinside60 number-block outline" style="background: #ffc107!important;height: 370px;">
                <div class="mb40"><img src="assets/images/6_22.png" /></div>
                <h2 class="capital-title" style="padding-top: 14px;color: #0E0057" translate>UN58 </h2>
                <p style="margin-left: -4px;padding-top: 30px;" translate>hr@goldfin.com.pk</p>
                <a href="#" class="btn-link" style="color: #0E0057;border-bottom: 2px solid #0E0057;padding-top: 20px;" translate>UN59</a> </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div class="bg-white pinside60 number-block outline" style="background: #ffc107!important;height: 370px;">
                <div class="mb40"><img src="assets/images/4_14.png" /></div>
                <h2 class="capital-title" style="color: #0E0057" translate>UN60</h2>
                <p translate>UN62</p>
                <a href="#" class="btn-link" style="color: #0E0057;border-bottom: 2px solid #0E0057;" translate>UN61</a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="section-space120 cta-section-app">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
        <div class="">
          <!-- section title start-->
          <h1 class="mb40" style="color: #0E0057" translate>UN63</h1>
          <a href="#" class="mr20"><img src="assets/images/app-store-img.png" alt=""></a>
          <a href="#" class="mr20"><img src="assets/images/google-play-img.png" alt=""></a>
        </div>
        <!-- /.section title start-->
      </div>
    </div>
  </div>
</div>
